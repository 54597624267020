import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function FileOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 20 20"} {...props}>
      <path
        id={"Vector"}
        d={
          "M11.6693 1.6665H5.0026C4.08594 1.6665 3.34427 2.4165 3.34427 3.33317L3.33594 16.6665C3.33594 17.5832 4.0776 18.3332 4.99427 18.3332H15.0026C15.9193 18.3332 16.6693 17.5832 16.6693 16.6665V6.6665L11.6693 1.6665ZM5.0026 16.6665V3.33317H10.8359V7.49984H15.0026V16.6665H5.0026Z"
        }
      />
    </IconBase>
  )
}
