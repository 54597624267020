import { Nullable } from "../../common/common"

export type Profile = {
  email: string
  familyName: string
  givenName: string
  fullName: string // @NOTE: front handling field
  picture: string
  locale: string
  jobTitle: string
  organizationName: string
}

type ProfileDto = {
  [key in keyof Profile]: Nullable<Profile[key]>
}

export const profileFromDto = (dto?: ProfileDto | null): Profile => {
  if (!dto) return initProfile()
  const _familyName = dto.familyName ?? ""
  const _givenName = dto.givenName ?? ""
  return {
    email: dto.email ?? "",
    familyName: _familyName,
    givenName: _givenName,
    fullName: [_givenName, _familyName].join(" "),
    picture: dto.picture ?? "",
    locale: dto.locale ?? "",
    jobTitle: dto.jobTitle ?? "",
    organizationName: dto.organizationName ?? "",
  }
}

export const initProfile = (): Profile => {
  return {
    email: "",
    familyName: "",
    givenName: "",
    fullName: "",
    picture: "",
    locale: "",
    jobTitle: "",
    organizationName: "",
  }
}
