import { disabledDate, getCalculatedDate, initRangePickerValue, Modal } from "@ovision-gis-frontend/shared"
import { Button, RangePicker } from "@SIAnalytics/ovision-design-system"
import React, { useEffect, useState } from "react"

import styles from "../GiveCreditModal.module.scss"

type Props = {
  value: [Date | null, Date | null]
  setValue: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>
  onDownloadButtonClick?: React.MouseEventHandler<HTMLElement>
}
function ExportCsvModal(props: Props) {
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      props.setValue(initRangePickerValue)
    }
  }, [])
  const handleRangeChange = (value: [Date | null, Date | null]) => {
    value[0]?.setHours(0, 0, 0, 0)
    value[1]?.setHours(23, 59, 59, 999)
    props.setValue(value)
  }
  const handleDownloadButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
    setLoading(true)
    props.onDownloadButtonClick?.(e)
  }

  return (
    <Modal
      className={styles.giveCreditModal}
      size={"small"}
      title={"다운로드"}
      content={
        <div className={styles.vertical}>
          <p>{"CSV로 다운로드할 기간을 설정해 주세요. (최대 10,000행)"}</p>
          <RangePicker
            size={"small"}
            combine={true}
            disabledDate={(currentDate) => disabledDate(currentDate, true)}
            format={"yyyy-MM-DD"}
            limited={true}
            value={props.value}
            onChange={handleRangeChange}
          />
        </div>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.onCloseButtonClick}>
              {"취소"}
            </Button>
            <Button size={"small"} loading={loading} onClick={handleDownloadButtonClick}>
              {"다운로드"}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.onCloseButtonClick}
    />
  )
}
export default ExportCsvModal
