const leftPad = (value: number | string, max = 2) => {
  return `${value}`.padStart(max, "0")
}
export const getTimeLabel = (time?: string) => {
  if (!time) return ""
  const _times = time.split(".")
  return _times.length !== 0
    ? _times[0]
        .split(":")
        .map((_time) => leftPad(_time))
        .join(":")
    : ""
}
export const convertSecToTime = (time?: number) => {
  if (!time) return ""
  return new Date(time * 1000).toISOString().substring(11, 19).replace("-", ":")
}
export const getDateLabel = (date?: string | Date, type: "TIL_DAY" | "TIL_MIN" | "TIL_SEC" = "TIL_MIN") => {
  if (!date) return ""

  const _date = typeof date === "string" ? new Date(date) : date
  const year = _date.getFullYear()
  const month = leftPad(_date.getMonth() + 1)
  const day = leftPad(_date.getDate())
  const hour = leftPad(_date.getHours())
  const min = leftPad(_date.getMinutes())
  const sec = leftPad(_date.getSeconds())

  if (type === "TIL_DAY") return [year, month, day].join("-")
  else if (type === "TIL_SEC") return `${[year, month, day].join("-")} ${[hour, min, sec].join(":")}`
  else return `${[year, month, day].join("-")} ${[hour, min].join(":")}`
}
export const disabledDate = (date: moment.Moment, untilLastYear = false) => {
  const today = new Date()
  today.setHours(23, 59, 59, 999)
  const lastYear = getCalculatedDate(today, -1, "YEAR")
  const _date = date.toDate()
  return _date > today || (untilLastYear && _date < lastYear)
}
export const initRangePickerValue = (): [Date | null, Date | null] => {
  const _start = getCalculatedDate(new Date(), -1, "MONTH")
  _start.setHours(0, 0, 0, 0)
  const _end = new Date()
  _end.setHours(23, 59, 59, 999)
  return [_start, _end]
}
export const getCalculatedDate = (
  baseDate: string | Date,
  addValue: number = 0,
  type?: "YEAR" | "MONTH" | "DAY" | "HOUR" | "MIN" | "SEC",
) => {
  const _base = new Date(baseDate)
  if (type === "YEAR") _base.setFullYear(_base.getFullYear() + addValue)
  else if (type === "MONTH") _base.setMonth(_base.getMonth() + addValue)
  else if (type === "HOUR") _base.setHours(_base.getHours() + addValue)
  else if (type === "MIN") _base.setMinutes(_base.getMinutes() + addValue)
  else if (type === "SEC") _base.setSeconds(_base.getSeconds() + addValue)
  else _base.setDate(_base.getDate() + addValue)
  return _base
}
export const isBaseDateEarlierThanTargetDate = (
  baseDate: string | Date,
  targetDate: string | Date,
  addValue: number = 0,
  type?: "YEAR" | "MONTH" | "DAY" | "HOUR" | "MIN" | "SEC",
) => {
  if (!baseDate || !targetDate) return false

  const _base = new Date(baseDate)
  const _target = getCalculatedDate(targetDate, addValue, type)
  return _base.getTime() < _target.getTime()
}
