import { NavigateDownOutlined } from "@SIAnalytics/ovision-design-system"
import { PaginationProps, Pagination, Select } from "antd"
import React, { useState } from "react"

import styles from "./CustomPagination.module.scss"

type Props = PaginationProps & {
  label?: string
}
function CustomPagination(props: Props) {
  const [pageSize, setPageSize] = useState<number>(10)

  const handleSizeChange = (value: number) => {
    setPageSize(value)
    props.onShowSizeChange?.(1, value)
  }

  return (
    <div className={styles.customPagination}>
      <div className={styles.pageSizer}>
        <p>{props.label || "페이지당 행 수"}</p> {/* @TODO: shared workspace로 이동 */}
        <Select
          className={"ods-page-size-changer"}
          dropdownClassName={"ods-page-size-changer-dropdown"}
          size={"small"}
          options={[
            { label: "10개씩 보기", value: 10 },
            { label: "20개씩 보기", value: 20 },
            { label: "50개씩 보기", value: 50 },
            { label: "100개씩 보기", value: 100 },
          ]}
          defaultValue={pageSize}
          suffixIcon={<NavigateDownOutlined />}
          onChange={handleSizeChange}
        />
      </div>
      <Pagination
        {...props}
        className={styles.pagination}
        size={"small"}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={(page, pageSize) => {
          setPageSize(pageSize)
          props.onChange?.(page, pageSize)
        }}
      />
    </div>
  )
}

export default CustomPagination
