import { Nullable } from "../../common/common"
import { AccountInfo, AccountInfoDto, accountInfoFromDto, initAccountInfo } from "./accountInfoDto"

export type AdminUserResult = {
  accountInfo: AccountInfo
  note: string
}
export type AdminUserResults = {
  results: AdminUserResult[]
  nextPages: string[]
}

type AdminUserResultToNullable = Omit<AdminUserResult, "accountInfo">
type AdminUserResultDto = {
  accountInfo: AccountInfoDto
} & {
  [key in keyof AdminUserResultToNullable]: Nullable<AdminUserResultToNullable[key]>
}
type AdminUserResultsToNullable = Omit<AdminUserResults, "results">
export type AdminUserResultsDto = {
  results: AdminUserResultDto[] | null
} & {
  [key in keyof AdminUserResultsToNullable]: Nullable<AdminUserResultsToNullable[key]>
}

export type AdminUserStatistics = {
  totalCount: number
}
export type AdminUserStatisticsDto = {
  totalCount: number | null
}

export const adminUserResultFromDto = (dto: AdminUserResultDto): AdminUserResult => {
  return {
    accountInfo: accountInfoFromDto(dto.accountInfo),
    note: dto.note ?? "",
  }
}
export const adminUserResultsFromDto = (dto: AdminUserResultsDto): AdminUserResults => {
  return {
    results: dto.results?.map((result) => adminUserResultFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}
export const adminUserStatisticsFromDto = (dto: AdminUserStatisticsDto): AdminUserStatistics => {
  return { totalCount: dto.totalCount ?? 0 }
}

export const initAdminUserResult = (): AdminUserResult => {
  return {
    accountInfo: initAccountInfo(),
    note: "",
  }
}
export const initAdminUserResults = (): AdminUserResults => {
  return {
    results: [initAdminUserResult()],
    nextPages: [],
  }
}
export const initAdminUserStatistics = (): AdminUserStatistics => {
  return { totalCount: 0 }
}
