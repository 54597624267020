import { AdminUserResult, CurrencyType, InvoiceType, Modal } from "@ovision-gis-frontend/shared"
import { Button, InputField, NavigateDownOutlined, RadioGroup } from "@SIAnalytics/ovision-design-system"
import { Select } from "antd"
import React, { useCallback, useEffect, useRef, useState } from "react"

import styles from "./GiveCreditModal.module.scss"

type Props = {
  creditAmount: number
  currency: CurrencyType
  files: FileList | null
  setFiles: React.Dispatch<React.SetStateAction<FileList | null>>
  invoiceType: InvoiceType
  price: number
  selectedUsers: AdminUserResult[]
  setCreditAmount: React.Dispatch<React.SetStateAction<number>>
  setCurrency: React.Dispatch<React.SetStateAction<CurrencyType>>
  setInvoiceType: React.Dispatch<React.SetStateAction<InvoiceType>>
  setPrice: React.Dispatch<React.SetStateAction<number>>
  closeModal?: () => void
  onApproveClick: React.MouseEventHandler<HTMLElement>
}

function GiveCreditModal(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  const isApproveButtonDisabled = !props.creditAmount || (props.invoiceType === "PAID" && !props.price)

  useEffect(() => {
    return () => {
      props.setInvoiceType("PAID")
      props.setPrice(0)
      props.setCurrency("USD")
      props.setCreditAmount(0)
      props.setFiles(null)
    }
  }, [])

  const fileInputHandler = useCallback((event: Event) => {
    const files = (event.target as HTMLInputElement).files
    if (files) props.setFiles(files)
  }, [])

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.addEventListener("change", fileInputHandler)

    return () => {
      inputRef.current?.removeEventListener("change", fileInputHandler)
    }
  }, [inputRef, fileInputHandler])

  return (
    <Modal
      className={styles.giveCreditModal}
      size={"small"}
      title={"크레딧 부여"}
      content={
        <>
          <div className={styles.vertical}>
            <p>{"크레딧 유형을 선택해 주세요."}</p>
            <RadioGroup
              type={"horizontal"}
              options={
                [
                  { label: "유료", value: "PAID" },
                  { label: "무료", value: "FREE" },
                  { label: "보상", value: "COMPENSATION" },
                  { label: "만료", value: "EXPIRATION", disabled: true },
                ] as { label: string; value: InvoiceType; disabled?: boolean }[]
              }
              value={props.invoiceType}
              onChange={(value) => props.setInvoiceType(value as InvoiceType)}
            />
          </div>
          <div className={styles.vertical}>
            <p>{"크레딧 수량을 입력해 주세요."}</p>
            <InputField
              id={"amount"}
              type={"number"}
              min={0}
              placeholder={"크레딧 수량"}
              onChange={(e) => props.setCreditAmount(parseInt(e.target.value))}
              onClearButtonClick={() => props.setCreditAmount(0)}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) e.preventDefault()
              }}
            />
          </div>
          {props.invoiceType === "PAID" && (
            <div className={styles.vertical}>
              <p>{"결제 금액을 입력해 주세요."}</p>
              <div className={styles.inputGroup}>
                <Select
                  className={styles.inputAction}
                  options={[
                    { label: "USD ($)", value: "USD" },
                    { label: "KRW (₩)", value: "KRW", disabled: true },
                  ]}
                  suffixIcon={<NavigateDownOutlined />}
                  value={props.currency}
                  onChange={(value) => props.setCurrency(value)}
                />
                <InputField
                  id={"price"}
                  type={"number"}
                  min={0}
                  placeholder={"결제 금액"}
                  onChange={(e) => props.setPrice(parseInt(e.target.value))}
                  onClearButtonClick={() => props.setPrice(0)}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) e.preventDefault()
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles.vertical}>
            <p>{"인보이스 문서를 첨부해 주세요. (선택)"}</p>
            <div className={styles.inputGroup}>
              <InputField
                className={styles.invoiceNameField}
                value={
                  (props.files?.item(0)?.name ?? "") +
                  (props.files?.length && props.files.length > 1 ? `  +${props.files.length - 1}` : "")
                }
                placeholder={"pdf, png, jpg, webp 파일"}
                readOnly={true}
              />
              <label className={styles.inputAction} htmlFor={"invoice"}>
                {"파일 선택"}
              </label>
            </div>
            <input
              id={"invoice"}
              ref={inputRef}
              type={"file"}
              accept={".pdf,.png,.jpg,.jpeg,.webp"}
              style={{ display: "none" }}
            />
          </div>
        </>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.closeModal}>
              {"취소"}
            </Button>
            <Button size={"small"} type={"primary"} disabled={isApproveButtonDisabled} onClick={props.onApproveClick}>
              {"승인"}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.closeModal}
    />
  )
}
export default GiveCreditModal
