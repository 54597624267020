import {
  AdminCreditFilterQueryType,
  AdminCreditHistoryResult,
  AdminCreditHistoryStatistics,
  AdminCreditSortType,
  CreditAllResultType,
  FileOutlined,
  getAdminCreditHistories,
  getAdminCreditHistoriesStatistics,
  getAdminInvoiceDocuments,
  getCreditAllTypeKoLabel,
  getCurrencyLabel,
  getDateLabel,
  getGrantCreditFlagKoLabel,
  initAdminCreditHistoryResult,
  initAdminCreditHistoryStatistics,
  initRangePickerValue,
  Loading,
  PhotoFrameOutlined,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  Chip,
  NavigateBeforeOutlined,
  NavigateDownOutlined,
  NavigateNextOutlined,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import { PaginationProps, Select, Table, TableProps } from "antd"
import type { ColumnsType, SorterResult } from "antd/es/table/interface"
import React, { useEffect, useRef, useState } from "react"

import { nextPageCount } from "../../common/tableUtil"
import styles from "../common.module.scss"
import CustomPagination from "../CustomPagination"
import TableTool from "../TableTool"
import Viewer from "../Viewer"
import ExportCsvModal from "./ExportCsvModal"
import InvoiceModal from "./InvoiceModal"

function CreditManagement() {
  const [updateFlag, setUpdateFlag] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [historiesDataSource, setHistoriesDataSource] = useState<AdminCreditHistoryResult[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [sort, setSort] = useState<AdminCreditSortType>("-CREATED_TIME")
  const [pageSize, setPageSize] = useState<number>(10)
  const historiesPageToken = useRef<string[]>([])
  const historiesPage = useRef<string>("")

  const [historyStatistics, setHistoryStatistics] = useState<AdminCreditHistoryStatistics>(initAdminCreditHistoryStatistics)
  const [isExportCsvModalVisible, setIsExportCsvModalVisible] = useState<boolean>(false)
  const [csvRangeValue, setCsvRangeValue] = useState<[Date | null, Date | null]>(initRangePickerValue)
  const downloadLinkRef = useRef<HTMLAnchorElement>(null)

  const [userValues, setUserValues] = useState<string[]>([])
  const [creditTypeFilter, setCreditTypeFilter] = useState<CreditAllResultType[]>([])
  const [searchKeyword, setSearchKeyword] = useState<string>("")
  const [suffixIcon, setSuffixIcon] = useState<React.ReactNode>(<NavigateDownOutlined />)

  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState<boolean>(false)
  const [isInferenceViewerVisible, setIsInferenceViewerVisible] = useState<boolean>(false)
  const [selectedRecordNote, setSelectedRecordNote] = useState<AdminCreditHistoryResult>(initAdminCreditHistoryResult)

  useEffect(() => {
    const setAdminCreditHistoriesStatisticsAsync = async () => {
      try {
        const _historyStatistics = await getAdminCreditHistoriesStatistics()
        setHistoryStatistics(_historyStatistics)
      } catch (e) {
        captureException(e)
      }
    }
    void setAdminCreditHistoriesStatisticsAsync()
  }, [])
  useEffect(() => {
    const setAdminCreditHistories = async () => {
      setLoading(true)
      const queryType: AdminCreditFilterQueryType = {
        page: historiesPage.current,
        pageSize: `${pageSize}`,
        nextPageCount: `${nextPageCount}`,
        accountId: userValues.join(","),
        type: creditTypeFilter.join(","),
        keyword: searchKeyword,
        sort: sort,
      }
      try {
        const _histories = await getAdminCreditHistories(queryType)
        const _invoiceHistories = _histories.results.filter((_history) =>
          _history.creditHistory.causedBy.startsWith("invoice"),
        )
        if (_invoiceHistories.length > 0) {
          const _invoiceIds = _invoiceHistories
            .reduce((acc: string[], cur) => {
              const _invoiceId = cur.creditHistory.causedByOnlyId
              if (!acc.includes(_invoiceId)) acc.push(_invoiceId)
              return acc
            }, [])
            .join(",")
          const _checkedInvoices = await getAdminInvoiceDocuments(_invoiceIds)
          for (const _invoice of _checkedInvoices.results) {
            for (const _history of _invoiceHistories) {
              if (_history.creditHistory.causedByOnlyId === _invoice.id.toString()) {
                _history.creditHistory.documentExists = _invoice.documentExists
                _history.creditHistory.documentName = _invoice.documentName
              }
            }
          }
        }
        setHistoriesDataSource(_histories.results)
        if (!historiesPageToken.current.length) historiesPageToken.current = ["", ..._histories.nextPages]
        else if (!historiesPageToken.current.includes(_histories.nextPages.at(0) ?? "null"))
          historiesPageToken.current = [...historiesPageToken.current, ..._histories.nextPages]
      } catch (e) {
        captureException(e)
      } finally {
        setLoading(false)
      }
    }
    void setAdminCreditHistories()
  }, [updateFlag, userValues, creditTypeFilter, searchKeyword, sort, historiesPage, pageSize, currentPage])

  const headers = ["No.", "사용자 계정", "유형", "크레딧", "보유 크레딧", "금액", "처리 일시", "비고"]
  const columns: ColumnsType<AdminCreditHistoryResult> = [
    {
      title: headers[0],
      dataIndex: "accountInfo.accountId",
      key: "ID",
      width: "4%",
      sorter: true,
      render: (text, record, index) => <p>{index + 1 + currentPage * pageSize}</p>,
    },
    {
      title: headers[1],
      dataIndex: "accountInfo.profile.email",
      key: "ACCOUNT_KEY",
      width: "18%",
      sorter: true,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={record.accountInfo.profile.email} placement={"bottom"}>
            <p>{record.accountInfo.profile.email}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      title: headers[2],
      dataIndex: "creditHistory.creditHistoryType",
      key: "TYPE",
      width: "15%",
      sorter: true,
      render: (text, record) => {
        const detail = getGrantCreditFlagKoLabel({ paid: record.creditHistory.paid, manual: record.creditHistory.manual })
        if (record.creditHistory.creditHistoryType === "RECHARGE") return <Chip color={"yellow"} label={"충전" + detail} />
        else if (record.creditHistory.creditHistoryType === "USAGE") return <Chip color={"blue"} label={"사용"} />
        else if (record.creditHistory.creditHistoryType === "EXPIRATION") return <Chip color={"grey"} label={"만료"} />
        else if (record.creditHistory.creditHistoryType === "REFUND")
          return <Chip className={"red"} label={"환불" + detail} />
        else if (record.creditHistory.creditHistoryType === "COMPENSATION")
          return <Chip color={"green"} label={"보상" + detail} />
      },
    },
    {
      title: headers[3],
      dataIndex: "creditHistory.creditAmount",
      key: "CREDIT_AMOUNT",
      width: "15%",
      align: "right",
      sorter: true,
      render: (text, record) => (
        <p>
          {record.creditHistory.creditAmount > 0
            ? "+" + record.creditHistory.creditAmount.toLocaleString()
            : record.creditHistory.creditAmount.toLocaleString()}
        </p>
      ),
    },
    {
      title: headers[4],
      dataIndex: "creditHistory.remainCredit",
      key: "REMAIN_CREDIT",
      width: "15%",
      align: "right",
      sorter: true,
      render: (text, record) => <p>{record.creditHistory.remainCredit.toLocaleString()}</p>,
    },
    {
      title: headers[5],
      dataIndex: "creditHistory.price",
      key: "creditHistory.price",
      width: "15%",
      align: "right",
      render: (text, record) => (
        <div className={styles.primary}>
          {record.creditHistory.creditHistoryType === "RECHARGE" && record.creditHistory.price !== 0
            ? getCurrencyLabel(record.creditHistory.currency) + record.creditHistory.price.toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      title: headers[6],
      dataIndex: "creditHistory.createdTime",
      key: "CREATED_TIME",
      width: "15%",
      sorter: true,
      defaultSortOrder: "descend",
      render: (text, record) => <p>{getDateLabel(record.creditHistory.createdTime, "TIL_SEC")}</p>,
    },
    {
      title: headers[7],
      dataIndex: "creditHistory.causedBy",
      key: "creditHistory.causedBy",
      width: "5%",
      render: (text, record) => {
        const handleInvoiceButtonClick = () => {
          setSelectedRecordNote(record)
          setIsInvoiceModalVisible(true)
        }
        const handleInferenceButtonClick = () => {
          setSelectedRecordNote(record)
          setIsInferenceViewerVisible(true)
        }
        if (
          record.creditHistory.creditHistoryType === "RECHARGE" ||
          record.creditHistory.creditHistoryType === "COMPENSATION"
        ) {
          return (
            <button
              style={{ cursor: "pointer", background: "initial", border: "initial" }}
              onClick={handleInvoiceButtonClick}
            >
              <FileOutlined
                style={{
                  color: record.creditHistory.documentExists ? "var(--icon-only)" : "var(--icon-secondary)",
                  fontSize: "20px",
                }}
              />
            </button>
          )
        } else if (record.creditHistory.creditHistoryType === "USAGE") {
          return (
            <button
              style={{ cursor: "pointer", background: "initial", border: "initial" }}
              onClick={handleInferenceButtonClick}
            >
              <PhotoFrameOutlined style={{ fontSize: "20px" }} />
            </button>
          )
        } else {
          return <></>
        }
      },
    },
  ]

  const clearPagination = () => {
    historiesPageToken.current = []
    historiesPage.current = ""
    setCurrentPage(0)
  }
  const clearPaginationWithFunction = (func: Function) => {
    clearPagination()
    func()
  }
  const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") return <NavigateBeforeOutlined />
    if (type === "next") return <NavigateNextOutlined />
    return originalElement
  }
  const handleCreditHistoriesPageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    historiesPage.current = historiesPageToken.current.at(_page) ?? ""
    setCurrentPage(historiesPage.current ? _page : 0)
  }
  const handleShowSizeChange: PaginationProps["onShowSizeChange"] = (current, size) => {
    setPageSize(size)
    clearPagination()
  }
  const handleTableChange: TableProps<AdminCreditHistoryResult>["onChange"] = (pagination, filters, sorter, extra) => {
    // @NOTE: columns의 각 key를 유효한 sort 값으로 설정
    const _sorter = sorter as SorterResult<AdminCreditSortType>
    const _order = _sorter.order === "descend" ? "-" : ""
    setSort((_order + _sorter.columnKey) as AdminCreditSortType)
  }

  const handleDownloadButtonClick = () => {
    const setAdminCreditHistoriesAsync = async () => {
      if (!downloadLinkRef.current) return
      const queryType: AdminCreditFilterQueryType = {
        pageSize: "1000",
        nextPageCount: "10",
        createdTimeStart: csvRangeValue[0]?.toISOString(),
        createdTimeEnd: csvRangeValue[1]?.toISOString(),
      }
      let _historyResults: AdminCreditHistoryResult[] = []
      try {
        const _histories = await getAdminCreditHistories(queryType)
        _historyResults = [..._historyResults, ..._histories.results]
        for await (const nextPage of _histories.nextPages) {
          const _nextHistories = await getAdminCreditHistories({ ...queryType, page: nextPage })
          _historyResults = [..._historyResults, ..._nextHistories.results]
        }

        const csvContent = [
          headers.join(","),
          ..._historyResults.map((row, index) =>
            [
              index + 1,
              row.accountInfo.profile.email,
              getCreditAllTypeKoLabel(row.creditHistory.creditHistoryType) +
                getGrantCreditFlagKoLabel({ paid: row.creditHistory.paid, manual: row.creditHistory.manual }),
              row.creditHistory.creditAmount,
              row.creditHistory.remainCredit,
              row.creditHistory.creditHistoryType === "RECHARGE" && row.creditHistory.price !== 0
                ? getCurrencyLabel(row.creditHistory.currency) + row.creditHistory.price
                : "",
              getDateLabel(row.creditHistory.createdTime, "TIL_SEC"),
              "",
            ].join(","),
          ),
        ].join("\n")
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
        downloadLinkRef.current.href = URL.createObjectURL(blob)
        downloadLinkRef.current.download = `크레딧_관리_${new Date().toLocaleString()}.csv`
        downloadLinkRef.current.click()
        Toast({ message: "다운로드가 완료되었습니다.", type: "success" })
      } catch (e) {
        Toast({ message: "다운로드에 실패했습니다. 다시 시도해 주세요.", type: "error" })
        captureException(e)
      } finally {
        setIsExportCsvModalVisible(false)
      }
    }
    void setAdminCreditHistoriesAsync()
  }

  return (
    <section className={styles.userManagement}>
      <TableTool
        className={styles.tableTool}
        title={"크레딧 관리"}
        downloadLinkRef={downloadLinkRef}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        setUserValues={(value) => clearPaginationWithFunction(() => setUserValues(value))}
        totalInfo={{ label: "전체 내역", value: `${historyStatistics.totalCount}건` }}
        userValues={userValues}
        onDownloadButtonClick={() => setIsExportCsvModalVisible(true)}
      >
        <Select
          options={
            [
              { label: "충전", value: "RECHARGE" },
              { label: "사용", value: "USAGE" },
              { label: "만료", value: "EXPIRATION" },
              { label: "환불", value: "REFUND" },
              { label: "보상", value: "COMPENSATION" },
            ] as { label: string; value: CreditAllResultType }[]
          }
          maxTagCount={"responsive"}
          mode={"multiple"}
          optionFilterProp={"label"}
          placeholder={"유형"}
          showArrow={true}
          showSearch={true}
          suffixIcon={suffixIcon}
          value={creditTypeFilter || null}
          onChange={(value) => clearPaginationWithFunction(() => setCreditTypeFilter(value))}
        />
      </TableTool>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={historiesDataSource}
        loading={{ indicator: <Loading className={styles.loading} size={"small"} />, spinning: loading }}
        pagination={false}
        rowKey={(record) => record.creditHistory.id}
        scroll={{ y: "100%" }}
        showSorterTooltip={false}
        onChange={handleTableChange}
      />
      <CustomPagination
        current={currentPage + 1}
        itemRender={itemRender}
        pageSize={pageSize}
        total={historiesPageToken.current.length * pageSize}
        onChange={handleCreditHistoriesPageChange}
        onShowSizeChange={handleShowSizeChange}
      />

      {isExportCsvModalVisible && (
        <ExportCsvModal
          setValue={setCsvRangeValue}
          value={csvRangeValue}
          onCloseButtonClick={() => setIsExportCsvModalVisible(false)}
          onDownloadButtonClick={handleDownloadButtonClick}
        />
      )}
      {isInvoiceModalVisible && (
        <InvoiceModal
          selectedRecordNote={selectedRecordNote}
          setUpdateFlag={setUpdateFlag}
          onCloseButtonClick={() => setIsInvoiceModalVisible(false)}
        />
      )}
      {isInferenceViewerVisible && (
        <Viewer selectedRecordNote={selectedRecordNote} onBackButtonClick={() => setIsInferenceViewerVisible(false)} />
      )}
    </section>
  )
}

export default CreditManagement
