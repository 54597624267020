import { Toast } from "@SIAnalytics/ovision-design-system"
import { message } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./useMobileWarn.scss"

export const useMobileWarn = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    if (isMobile) {
      Toast({
        message: t("toast.mobile.info"),
        type: "warning",
        closable: true,
        className: "mobile-warn",
        key: "mobile-warn",
      })
    }

    return () => {
      message.destroy("mobile-warn")
    }
  }, [])
}
