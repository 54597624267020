import GoogleGreyLogoImg from "../../asset/logo/GoogleGreyLogo.png"
import GoogleLogoImg from "../../asset/logo/GoogleLogo.png"
import OvisionGreyLogoImg from "../../asset/logo/OvisionGreyLogo.png"
import OvisionLogoImg from "../../asset/logo/OvisionLogo.png"
import SiaGreyLogoImg from "../../asset/logo/SiaGreyLogo.png"
import SiaLogoImg from "../../asset/logo/SiaLogo.png"
import { changeToCamel } from "../common"

export type AccountId = `user:${number}`
export type PermissionGroupType = `permission-group.role.${string}`
export type PermissionInferenceType = `permission.inference.aipack.${string}`
export type PermissionType = `permission.${string}`

export type AccountType = "SIA" | "GOOGLE" | "OVISION"
export type PermissionGroup = "SuperAdmin" | "Admin" | "Default" | "System"
export type PermissionGroupKoLabel = "슈퍼 관리자" | "관리자" | "일반 사용자" | "시스템"

export const isAccountTypeValid = (type: string): type is AccountType => {
  return type === "SIA" || type === "GOOGLE" || type === "OVISION"
}

export const permissionGroupRole = "permission-group.role"
export const permissionGroupRoleSuperAdmin = "permission-group.role.super-admin"
export const permissionGroupRoleAdmin = "permission-group.role.admin"
export const permissionGroupRoleSystem = "permission-group.role.system"
export const permissionGroupRoleDefault = "permission-group.role.default"
export const permissionInferenceAipack = "permission.inference.aipack"
export const permissionAccessAdminPage = "permission.access.admin-page"
export const superEnhancement = "super-enhancement"

export const getPermissions = (permissions: string[], prefix: string, korean = false) => {
  return permissions
    .filter((permission) => permission.includes(prefix))
    .map((permission) => {
      const _permission = changeToCamel(permission.replace(`${prefix}.`, ""), false)
      return korean ? getPermissionKoLabel(_permission) : _permission
    })
}
export const getPickOrderedPermissions = (permissions: string[], prefix: string, pick: string) => {
  const _permissions = getPermissions(permissions, prefix)
  const _pick = changeToCamel(pick, false)
  return _permissions
    .filter((_permission) => _permission === _pick)
    .concat(_permissions.filter((_permission) => _permission !== _pick))
}
export const getPermissionKoLabel = (permission: string): PermissionGroupKoLabel => {
  if (permission === "SuperAdmin") return "슈퍼 관리자"
  else if (permission === "Admin") return "관리자"
  else if (permission === "System") return "시스템"
  else return "일반 사용자"
}

export const getAccountTypeLogo = (types: AccountType[], disabled: boolean): string => {
  if (types.includes("SIA")) return disabled ? SiaGreyLogoImg : SiaLogoImg
  else if (types.includes("OVISION")) return disabled ? OvisionGreyLogoImg : OvisionLogoImg
  else return disabled ? GoogleGreyLogoImg : GoogleLogoImg
}
