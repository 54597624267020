import { Collection, Map as OlMap, View } from "ol"
import { Control } from "ol/control"
import { Extent } from "ol/extent"
import { GeoJSON, WKB, WMTSCapabilities } from "ol/format"
import BaseLayer from "ol/layer/Base"
import LayerGroup from "ol/layer/Group"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import { fromLonLat } from "ol/proj"
import { OSM, WMTS, XYZ } from "ol/source"
import TileSource from "ol/source/Tile"
import VectorSource from "ol/source/Vector"
import { optionsFromCapabilities } from "ol/source/WMTS"
import { Fill, Style } from "ol/style"
import { FlatStyleLike } from "ol/style/flat"
import { StyleLike } from "ol/style/Style"
import { ViewOptions } from "ol/View"

import { fetchRetry, getProviderFromUrl, getToken, ProviderType } from "../../../index"
import PlusImg from "../../../src/asset/icons/outlined/Plus.png"

export const LAYER_TILE_OSM = "layer-tile-osm"
export const LAYER_TILE_OSM_Z_INDEX = 0
export const LAYER_TILE_ANALYSIS = "layer-tile-analysis"
export const LAYER_TILE_ANALYSIS_Z_INDEX = 5
export const LAYER_TILE_MY_IMAGES = "layer-tile-my-images"
export const LAYER_TILE_MY_IMAGES_Z_INDEX = 5
export const LAYER_TILE_CREATE_PROJECT_PROVIDER = "layer-tile-create-project-provider"
export const LAYER_TILE_CREATE_PROJECT_PROVIDER_Z_INDEX = 10
export const LAYER_TILE_CREATE_PROJECT_MY_IMAGES = "layer-tile-create-project-my-images"
export const LAYER_TILE_CREATE_PROJECT_MY_IMAGES_Z_INDEX = 10
export const LAYER_VECTOR_ANALYSIS = "layer-vector-analysis"
export const LAYER_VECTOR_ANALYSIS_Z_INDEX = 50
export const LAYER_VECTOR_AOI = "layer-vector-aoi"
export const LAYER_VECTOR_AOI_Z_INDEX = 55

export const LAYER_TILE_WEATHEO_RAIN = "layer-tile-weatheo-rain"
export const LAYER_WEATHEO_RAIN_Z_INDEX = 105
export const LAYER_WEATHEO_CLOUD_Z_INDEX = 100
export const LAYER_TILE_VI = "layer-tile-vi"
export const LAYER_VI_Z_INDEX = 10
export const LAYER_TILE_IR = "layer-tile-ir"
export const LAYER_IR_Z_INDEX = 5

export const LAYER_TILE_ADMIN_VIEWER = "layer-tile-admin-viewer"
export const LAYER_TILE_ADMIN_VIEWER_Z_INDEX = 5

export const CONTROL_SWIPE = "control-swipe"

export const COORDINATE_DAEJEON = fromLonLat([127.3845475, 36.3504119])

export const AREA_AOI_LIMIT = 100e6
export const AREA_AOI_LIMIT_VEHICLE_DETECTION = 40e6

export const VECTOR_COLOR_OBJECT_DETECTION = "#5FFF9F"
export const LEGEND_COLOR_LAND_COVER_BARELAND = "#E6C6AD"
export const LEGEND_COLOR_LAND_COVER_RANGELAND = "#AEC6A4"
export const LEGEND_COLOR_LAND_COVER_DEVELOPED_SPACE = "#757576"
export const LEGEND_COLOR_LAND_COVER_ROAD = "#FFFFFF"
export const LEGEND_COLOR_LAND_COVER_TREE = "#85F568"
export const LEGEND_COLOR_LAND_COVER_WATER = "#1935DD"
export const LEGEND_COLOR_LAND_COVER_AGRICULTURE_LAND = "#D5C946"
export const LEGEND_COLOR_LAND_COVER_BUILDING = "#527F92"

export const IMAGE_ANALYSIS_GSD_COST = 10.0
// Super Enhancement
export const GSD_HIGH_RES = [0.0, 0.1, 0.2]
export const GSD_HIGH_RES_COST = 20.0
export const GSD_MID_RES = [0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]
export const GSD_MID_RES_COST = 10.0
export const GSD_LOW_RES = [1.0, null]
export const GSD_LOW_RES_COST = 5.0

export const getProviderGsd = (zoom: number, latitude: number) => {
  return ((156543.03 / 2) * Math.cos((latitude * Math.PI) / 180)) / 2 ** zoom
}

export const getLayers = (map: OlMap, name: string): BaseLayer[] => {
  if (!map) return []
  return map.getAllLayers().filter((_layer) => _layer.get("name") === name)
}
export const removeLayers = (map: OlMap, name: string, clearTileEvent = false): (BaseLayer | undefined)[] => {
  if (!map) return []
  return getLayers(map, name).map((_layer) => {
    if (clearTileEvent) {
      const _tile = _layer as TileLayer<TileSource>
      _tile.getSource()?.un("tileloadstart", () => {})
      _tile.getSource()?.un(["tileloadend", "tileloaderror"], () => {})
    }
    return map.removeLayer(_layer)
  })
}

export const generateTileLayer = (
  source: TileSource,
  preload = 0,
  extent?: Extent,
  name?: string,
  zIndex?: number | undefined,
) => {
  return new TileLayer({ source: source, preload: preload, extent: extent, properties: { name: name }, zIndex: zIndex })
}

export const generateOsmLayer = (className?: string) => {
  return new TileLayer({
    className,
    preload: Infinity,
    source: new OSM({
      attributions: [],
      wrapX: true,
    }),
    properties: { name: LAYER_TILE_OSM },
    zIndex: LAYER_TILE_OSM_Z_INDEX,
  })
}

export const generateXyzTileLayer = (
  sceneUrl: string,
  preload = 0,
  extent?: Extent,
  name?: string,
  zIndex?: number | undefined,
) => {
  return generateTileLayer(new XYZ({ url: sceneUrl, crossOrigin: "anonymous" }), preload, extent, name, zIndex)
}

export const generateUploadedTileLayer = (sceneUrl: string, extent: Extent, name: string, zIndex?: number | undefined) => {
  const token = getToken()
  if (!token) throw Error("Token error")
  return generateXyzTileLayer(sceneUrl + `?access_token=${token}`, 0, extent, name, zIndex)
}

/* TODO: VectorSource func refactor */
export const generateVectorSource = (wrapX: boolean = false) => {
  return new VectorSource({ wrapX: wrapX })
}
export const generateVectorSourceFromGeoJson = (geoJson: string) => {
  return new VectorSource({
    format: new GeoJSON(),
    url: geoJson,
  })
}
export const generateVectorSourceFromWkb = (wkb: string) => {
  return new VectorSource({
    features: new WKB().readFeatures(wkb, { dataProjection: "EPSG:4326", featureProjection: "EPSG:3857" }),
  })
}

export const generateWmtsSource = (
  provider: ProviderType,
  url: string,
  config: { layer: string; matrixSet: string; crossOrigin: string },
) => {
  if (!url) return null
  const parser = new WMTSCapabilities()
  return fetch(url)
    .then((res) => res.text())
    .then((text) => {
      const _text = provider === "WAYBACK" ? text.replaceAll("https", "http") : text
      const result = parser.read(_text)

      let layers: Object[] = []
      if ("Contents" in result && "Layer" in result["Contents"]) layers = result["Contents"]["Layer"]
      else return null

      const _config = config
      if (_config.layer === "WAYBACK") {
        if (layers.length > 0 && "Identifier" in layers[0]) _config.layer = layers[0]["Identifier"]!.toString()
        else return null
      }

      const options = optionsFromCapabilities(result, _config)
      if (options) options.wrapX = true
      return options ? { source: new WMTS(options), layers } : null
    })
}

export const generateUploadWmtsSceneLayer = (url: string, extent: number[] | undefined, name: string, zIndex?: number) => {
  const token = getToken()
  if (!token) throw new Error("Token error")

  return generateWmtsSceneLayer(null, url + `&access_token=${token}`, extent, name, zIndex)
}

export const generateWmtsSceneLayer = (
  provider: ProviderType | null,
  url: string,
  extent: number[] | undefined,
  name: string,
  zIndex?: number,
) => {
  return fetchRetry(url)
    .then((res) => res.text())
    .then((text) => {
      const parser = new WMTSCapabilities()
      const _text = provider === "WAYBACK" ? text.replaceAll("https", "http") : text
      const result = parser.read(_text)

      const options = optionsFromCapabilities(result, {
        layer: result.Contents.Layer[0].Identifier,
        crossOrigin: "anonymous",
      })
      if (!options) throw Error("Wrong WMTSCapabilities")
      return new TileLayer({
        extent: extent,
        source: new WMTS(options),
        properties: { name: name },
        zIndex: zIndex,
      })
    })
}
export const getTileLayer = async (url: string, extent: Extent, layer: string, index: number) => {
  const isUploadedTile = /ovision/.test(url)
  if (isUploadedTile) {
    return url.includes("WMTSCapabilities")
      ? generateUploadWmtsSceneLayer(url, extent, layer, index)
      : generateUploadedTileLayer(url, extent, layer, index)
  } else {
    return url.includes("WMTSCapabilities")
      ? generateWmtsSceneLayer(getProviderFromUrl(url), url, extent, layer, index)
      : generateXyzTileLayer(url, 0, extent, layer, index)
  }
}

const setObjectDetectionStyle = (width: number = 2): StyleLike | FlatStyleLike => {
  return { "stroke-color": VECTOR_COLOR_OBJECT_DETECTION, "stroke-width": width }
}
const setSegmentationStyle = (): StyleLike | FlatStyleLike => {
  return { "fill-color": "rgba(255, 146, 146, 0.75)" }
}
export const setAoiStyle = (width: number = 3, drawing?: boolean): StyleLike | FlatStyleLike => {
  return {
    "fill-color": drawing ? "rgba(255, 211, 146, 0.25)" : undefined,
    "stroke-color": "#FF9900",
    "stroke-width": width,
    "icon-src": PlusImg,
  }
}
export const generateVectorLayer = (
  source: VectorSource,
  name: string,
  style: StyleLike | FlatStyleLike = setObjectDetectionStyle(),
  zIndex?: number | undefined,
) => {
  return new VectorLayer({
    source: source,
    style: style,
    properties: { name: name },
    zIndex: zIndex,
  })
}
export const generateVectorLayerForAoi = (source: VectorSource, name: string = LAYER_VECTOR_AOI) => {
  return generateVectorLayer(source, name, setAoiStyle(3), LAYER_VECTOR_AOI_Z_INDEX)
}
export const generateVectorLayerForObjectDetection = (source: VectorSource) => {
  return generateVectorLayer(source, LAYER_VECTOR_ANALYSIS, setObjectDetectionStyle(), LAYER_VECTOR_ANALYSIS_Z_INDEX)
}
export const generateVectorLayerForSegmentation = (source: VectorSource) => {
  return generateVectorLayer(source, LAYER_VECTOR_ANALYSIS, setSegmentationStyle(), LAYER_VECTOR_ANALYSIS_Z_INDEX)
}
// 0: 'Bare land', 1: 'Range land', 2: 'Developed space', 3: 'Road', 4: 'Tree', 5: 'Water', 6: 'Agriculture land', 7: 'Building'
export const generateVectorLayerForLand = (source: VectorSource) => {
  return new VectorLayer({
    source: source,
    style: (feature) => {
      let color = "transparent"
      if (feature.get("classId") === 0) color = LEGEND_COLOR_LAND_COVER_BARELAND
      else if (feature.get("classId") === 1) color = LEGEND_COLOR_LAND_COVER_RANGELAND
      else if (feature.get("classId") === 2) color = LEGEND_COLOR_LAND_COVER_DEVELOPED_SPACE
      else if (feature.get("classId") === 3) color = LEGEND_COLOR_LAND_COVER_ROAD
      else if (feature.get("classId") === 4) color = LEGEND_COLOR_LAND_COVER_TREE
      else if (feature.get("classId") === 5) color = LEGEND_COLOR_LAND_COVER_WATER
      else if (feature.get("classId") === 6) color = LEGEND_COLOR_LAND_COVER_AGRICULTURE_LAND
      else if (feature.get("classId") === 7) color = LEGEND_COLOR_LAND_COVER_BUILDING
      return new Style({ fill: new Fill({ color: color }) })
    },
    properties: { name: LAYER_VECTOR_ANALYSIS },
    zIndex: LAYER_VECTOR_ANALYSIS_Z_INDEX,
  })
}

export const generateOlMap = (
  layers?: BaseLayer[] | Collection<BaseLayer> | LayerGroup,
  target?: string | HTMLElement,
  view?: View | Promise<ViewOptions>,
  controls?: Collection<Control> | Control[],
) => {
  return new OlMap({ layers: layers, target: target, view: view, controls: controls })
}
