import { Nullable } from "../../common/common"
import { AdminScene, AdminSceneDto, adminSceneFromDto, initAdminScene } from "./adminScenesDto"

export type AdminInferenceHistoryResult = {
  id: string
  scene: AdminScene
  geojsonURI: string
}
type AdminInferenceHistoryResultToNullable = Omit<AdminInferenceHistoryResult, "scene">
export type AdminInferenceHistoryResultDto = {
  scene: AdminSceneDto | null
} & {
  [key in keyof AdminInferenceHistoryResultToNullable]: Nullable<AdminInferenceHistoryResultToNullable[key]>
}

export const adminInferenceHistoryResultFromDto = (dto: AdminInferenceHistoryResultDto): AdminInferenceHistoryResult => {
  return {
    id: dto.id ?? "",
    scene: adminSceneFromDto(dto.scene),
    geojsonURI: dto.geojsonURI ?? "",
  }
}

export const initAdminInferenceHistoryResult = (): AdminInferenceHistoryResult => {
  return {
    id: "",
    scene: initAdminScene(),
    geojsonURI: "",
  }
}

export const isInitialAdminInferencceHistoryResult = (result: AdminInferenceHistoryResult) => {
  return result.id === ""
}
