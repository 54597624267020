import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { OE_REDIRECT_PATH, PATH_SIGN_IN, PATH_SIGN_IN_ADD_INFO, PATH_ANALYSIS } from "../../.."
import { postOauthSignIn } from "../../common/axiosApi"
import { decodeFromBase64, parseJwt } from "../../common/common"
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../../hooks/data/useToken"
import Fallback from "../fallback/Fallback"

function OauthGoogleCode() {
  const params = new URLSearchParams(location.search)
  const state = params.get("state") ?? ""
  const code = params.get("code")
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    ;(async function () {
      if (code) {
        try {
          const res = await postOauthSignIn("GOOGLE", code)
          localStorage[ACCESS_TOKEN_KEY] = res.accessToken
          localStorage[REFRESH_TOKEN_KEY] = res.refreshToken

          const parsedJwt = parseJwt(res.accessToken)
          const hasInfo = parsedJwt?.profile?.organizationName && parsedJwt?.profile?.jobTitle

          const redirectPath = state === "" ? PATH_ANALYSIS : decodeFromBase64(state)

          if (hasInfo) {
            navigate(redirectPath)
          } else {
            navigate(PATH_SIGN_IN_ADD_INFO, {
              replace: true,
              state: { [OE_REDIRECT_PATH]: redirectPath },
            })
          }
        } catch (e: any) {
          if (e.status === 403) {
            Toast({ type: "error", message: e.data.errorInfo.message })
          } else if (e.status === 409) {
            Toast({ type: "error", message: t("toast.signIn.oeAccount.error") })
          } else {
            Toast({ type: "error", message: t("toast.signIn.error") })
            captureException(e)
          }
          navigate(PATH_SIGN_IN, { replace: true })
        }
      }
    })()
  }, [])

  return <Fallback />
}

export default OauthGoogleCode
