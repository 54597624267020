import { Nullable } from "../../common/common"
import { AccountId, AccountType, PermissionGroupType, PermissionType } from "../../common/utils/accountUtil"
import { initProfile, Profile, profileFromDto } from "./ProfileDto"

export type AccountInfo = {
  accountTypes: AccountType[]
  accountId: AccountId
  key: string
  profile: Profile
  permissionGroups: PermissionGroupType[]
  permissions: PermissionType[]
  remainCredit: number
  usedCredit: number
  createdTime: Date | ""
  lastLogin: Date | ""
  removed: boolean
}
type AccountInfoToNullable = Omit<
  AccountInfo,
  "accountTypes" | "accountId" | "permissionGroups" | "permissions" | "createdTime" | "lastLogin"
>
export type AccountInfoDto = {
  accountTypes: AccountType[] | null
  accountId: AccountId | null
  permissionGroups: PermissionGroupType[] | null
  permissions: PermissionType[] | null
  createdTime: string | null
  lastLogin: string | null
} & {
  [key in keyof AccountInfoToNullable]: Nullable<AccountInfoToNullable[key]>
}

export const accountInfoFromDto = (dto?: AccountInfoDto | null): AccountInfo => {
  if (!dto) return initAccountInfo()
  return {
    accountTypes: dto.accountTypes ?? [],
    accountId: dto.accountId ?? "user:-1",
    key: dto.key ?? "",
    profile: profileFromDto(dto.profile),
    permissionGroups: dto.permissionGroups ?? ["permission-group.role."],
    permissions: dto.permissions ?? ["permission."],
    remainCredit: dto.remainCredit ?? 0,
    usedCredit: dto.usedCredit ?? 0,
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    lastLogin: dto.lastLogin ? new Date(dto.lastLogin) : "",
    removed: dto.removed ?? false,
  }
}

export const initAccountInfo = (): AccountInfo => {
  return {
    accountTypes: [],
    accountId: "user:-1",
    key: "",
    profile: initProfile(),
    permissionGroups: ["permission-group.role."],
    permissions: ["permission."],
    remainCredit: 0,
    usedCredit: 0,
    createdTime: "",
    lastLogin: "",
    removed: false,
  }
}
