import {
  AdminInvoices,
  AdminUserResult,
  ConfirmModal,
  CurrencyType,
  InvoiceType,
  patchAdminInvoiceId,
  postAdminInvoiceCompensation,
  postAdminInvoiceDocument,
  postAdminInvoiceFree,
  postAdminInvoicePaid,
  uploadBySignedUrl,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import React, { useState } from "react"

import GiveCreditModal from "./GiveCreditModal"

type Props = {
  isGiveCreditModalVisible: boolean
  setIsGiveCreditModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  selectedUsers: AdminUserResult[]
  setUpdateFlag?: React.Dispatch<React.SetStateAction<boolean>>
}
function GiveCredit(props: Props) {
  const [invoiceType, setInvoiceType] = useState<InvoiceType>("PAID")
  const [price, setPrice] = useState<number>(0)
  const [currency, setCurrency] = useState<CurrencyType>("USD")
  const [creditAmount, setCreditAmount] = useState<number>(0)
  const [files, setFiles] = useState<FileList | null>(null)

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleConfirmButtonClick = () => {
    const postAdminInvoiceAsync = async () => {
      setLoading(true)
      const accountIds = props.selectedUsers.map((users) => users.accountInfo.accountId)
      try {
        let _invoices: AdminInvoices = { invoices: [] }
        if (invoiceType === "PAID") _invoices = await postAdminInvoicePaid({ accountIds, creditAmount, price, currency })
        else if (invoiceType === "FREE") _invoices = await postAdminInvoiceFree({ accountIds, creditAmount })
        else if (invoiceType === "COMPENSATION") _invoices = await postAdminInvoiceCompensation({ accountIds, creditAmount })

        const file = files?.item(0)
        if (file) {
          // @NOTE: Support single file.
          for await (const invoice of _invoices.invoices) {
            const { document } = await postAdminInvoiceDocument(file.name)
            await uploadBySignedUrl(document, file)
            await patchAdminInvoiceId(invoice.id.toString(), document)
          }
        }

        Toast({ message: "크레딧을 부여했습니다.", type: "success" })
        props.setUpdateFlag?.((prev) => !prev)
      } catch (e) {
        Toast({ message: "크레딧을 부여하는데 실패했습니다. 다시 시도해 주세요.", type: "error" })
        captureException(e)
      } finally {
        setLoading(false)
        setIsConfirmModalVisible(false)
        props.setIsGiveCreditModalVisible(false)
      }
    }
    void postAdminInvoiceAsync()
  }

  const geInvoiceType = (type: InvoiceType) => {
    if (type === "PAID") return "유료"
    else if (type === "FREE") return "무료"
    else if (type === "COMPENSATION") return "보상"
    else if (type === "EXPIRATION") return "만료"
  }

  return (
    <>
      {props.isGiveCreditModalVisible && (
        <GiveCreditModal
          creditAmount={creditAmount}
          currency={currency}
          files={files}
          invoiceType={invoiceType}
          price={price}
          selectedUsers={props.selectedUsers}
          setCreditAmount={setCreditAmount}
          setCurrency={setCurrency}
          setFiles={setFiles}
          setInvoiceType={setInvoiceType}
          setPrice={setPrice}
          closeModal={() => props.setIsGiveCreditModalVisible(false)}
          onApproveClick={() => setIsConfirmModalVisible(true)}
        />
      )}
      {isConfirmModalVisible && (
        <ConfirmModal
          title={`${props.selectedUsers.length}개의 계정에 ${creditAmount.toLocaleString()} ${geInvoiceType(
            invoiceType,
          )} 크레딧을 부여 하시겠습니까?`}
          confirmText={"부여"}
          isConfirmButtonLoading={loading}
          isPrimaryConfirm={true}
          onCloseButtonClick={() => setIsConfirmModalVisible(false)}
          onConfirmButtonClick={handleConfirmButtonClick}
        />
      )}
    </>
  )
}
export default GiveCredit
