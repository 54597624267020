import { Nullable } from "../../common/common"
import { AccountId } from "../../common/utils/accountUtil"
import { isSceneStatusValid, SceneStatus } from "./scenesDto"

export type AdminSceneStatus = SceneStatus | "DOWNLOADING" | "DOWNLOADED"
export const isAdminSceneStatusValid = (status: string | null): status is AdminSceneStatus => {
  return isSceneStatusValid(status) || status === "DOWNLOADING" || status === "DOWNLOADED"
}

export type AdminRegion = {
  countryCode: string
  state: string
  city: string
}
export type AdminScene = {
  id: string
  name: string
  region: AdminRegion
  satellite: string
  status: AdminSceneStatus
  accountId: AccountId
  imagingTime: Date | ""
  createdTime: Date | ""
  thumbnailURI: string
  // @DEPRECATED
  sceneURI: string
  wmsUrl: string
  gsdX: number
  gsdY: number
}
export type AdminScenes = {
  results: AdminScene[]
  nextPages: string[]
}

export type AdminRegionDto = {
  [key in keyof AdminRegion]: Nullable<AdminRegion[key]>
}
type AdminSceneToNullable = Omit<AdminScene, "region" | "imagingTime" | "createdTime">
export type AdminSceneDto = {
  region: AdminRegionDto | null
  imagingTime: string | null
  createdTime: string | null
} & {
  [key in keyof AdminSceneToNullable]: Nullable<AdminSceneToNullable[key]>
}
type AdminScenesToNullable = Omit<AdminScenes, "results">
export type AdminScenesDto = {
  results: AdminSceneDto[] | null
} & {
  [key in keyof AdminScenesToNullable]: Nullable<AdminScenesToNullable[key]>
}

const adminRegionFromDto = (dto: AdminRegionDto | null): AdminRegion => {
  if (!dto) return initAdminRegion()
  return {
    countryCode: dto.countryCode ?? "",
    state: dto.state ?? "",
    city: dto.city ?? "",
  }
}
export const adminSceneFromDto = (dto: AdminSceneDto | null): AdminScene => {
  if (!dto) return initAdminScene()
  const _status = isAdminSceneStatusValid(dto.status) ? dto.status : ""
  return {
    id: dto.id ?? "",
    name: dto.name ?? "",
    region: adminRegionFromDto(dto.region),
    satellite: dto.satellite ?? "",
    status: _status,
    accountId: dto.accountId ?? "user:-1",
    imagingTime: dto.imagingTime ? new Date(dto.imagingTime) : "",
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    thumbnailURI: dto.thumbnailURI ?? "",
    sceneURI: dto.sceneURI ?? "",
    wmsUrl: dto.wmsUrl ?? "",
    gsdX: dto.gsdX ?? 0,
    gsdY: dto.gsdY ?? 0,
  }
}
export const adminScenesFromDto = (dto: AdminScenesDto): AdminScenes => {
  return {
    results: dto.results?.map((result) => adminSceneFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}

const initAdminRegion = (): AdminRegion => {
  return {
    countryCode: "",
    state: "",
    city: "",
  }
}
export const initAdminScene = (): AdminScene => {
  return {
    id: "",
    name: "",
    region: initAdminRegion(),
    satellite: "",
    status: "",
    accountId: "user:-1",
    imagingTime: "",
    createdTime: "",
    thumbnailURI: "",
    sceneURI: "",
    wmsUrl: "",
    gsdX: 0,
    gsdY: 0,
  }
}
