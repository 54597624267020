import {
  AccountType,
  AdminAccountPermissions,
  AdminUserFilterQueryType,
  AdminUserResult,
  AdminUserSortType,
  AdminUserStatistics,
  DocumentsOutlined,
  getAccountTypeLogo,
  getAdminPermissionsAccounts,
  getAdminUsers,
  getAdminUsersStatistics,
  getDateLabel,
  getPermissions,
  initAdminAccountPermissions,
  initAdminUserResult,
  initAdminUserStatistics,
  initRangePickerValue,
  LoaderData,
  Loading,
  PermissionGroupKoLabel,
  permissionGroupRole,
  PermissionGroupType,
  putAdminUsersNote,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  NavigateBeforeOutlined,
  NavigateDownOutlined,
  NavigateNextOutlined,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import { PaginationProps, TableProps, Select, Table } from "antd"
import type { ColumnsType, SorterResult } from "antd/es/table/interface"
import React, { useEffect, useRef, useState } from "react"
import { useRouteLoaderData } from "react-router-dom"

import { nextPageCount } from "../../common/tableUtil"
import styles from "../common.module.scss"
import ExportCsvModal from "../credit-management/ExportCsvModal"
import CustomPagination from "../CustomPagination"
import { HomeLoader } from "../home/Home"
import TableTool from "../TableTool"
import MemoModal from "./MemoModal"

function UserManagement() {
  const { user } = useRouteLoaderData("home") as LoaderData<typeof HomeLoader>
  const [adminAccountPermissions, setAdminAccountPermissions] =
    useState<AdminAccountPermissions>(initAdminAccountPermissions)

  const [updateFlag, setUpdateFlag] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [usersDataSource, setUsersDataSource] = useState<AdminUserResult[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [sort, setSort] = useState<AdminUserSortType>("-CREATED_TIME")
  const [pageSize, setPageSize] = useState<number>(10)
  const usersPageToken = useRef<string[]>([])
  const usersPage = useRef<string>("")

  const [userStatistics, setUserStatistics] = useState<AdminUserStatistics>(initAdminUserStatistics)
  const [isExportCsvModalVisible, setIsExportCsvModalVisible] = useState<boolean>(false)
  const [csvRangeValue, setCsvRangeValue] = useState<[Date | null, Date | null]>(initRangePickerValue)
  const downloadLinkRef = useRef<HTMLAnchorElement>(null)

  const [userValues, setUserValues] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<AdminUserResult[]>([])
  const [accountTypeFilter, setAccountTypeFilter] = useState<AccountType[]>([])
  const [permissionGroupFilter, setPermissionGroupFilter] = useState<PermissionGroupType[]>([])
  const [searchKeyword, setSearchKeyword] = useState<string>("")
  const [suffixIcon, setSuffixIcon] = useState<React.ReactNode>(<NavigateDownOutlined />)

  const [isMemoModalVisible, setIsMemoModalVisible] = useState<boolean>(false)
  const [noteLoading, setNoteLoading] = useState<boolean>(false)
  const [selectedRecordNote, setSelectedRecordNote] = useState<AdminUserResult>(initAdminUserResult)

  useEffect(() => {
    const setAdminUserStatisticsAsync = async () => {
      try {
        const _userStatistics = await getAdminUsersStatistics()
        setUserStatistics(_userStatistics)
      } catch (e) {
        captureException(e)
      }
    }
    const setAdminAccountPermissionsAsync = async () => {
      if (!user.account.id) return
      try {
        const _permissions = await getAdminPermissionsAccounts(user.account.id)
        setAdminAccountPermissions(_permissions)
      } catch (e) {
        captureException(e)
      }
    }
    void setAdminUserStatisticsAsync()
    void setAdminAccountPermissionsAsync()
  }, [])
  useEffect(() => {
    const setAdminUsersAsync = async () => {
      setLoading(true)
      const queryType: AdminUserFilterQueryType = {
        page: usersPage.current,
        pageSize: `${pageSize}`,
        nextPageCount: `${nextPageCount}`,
        accountId: userValues.join(","),
        accountType: accountTypeFilter.join(","),
        permissionGroup: permissionGroupFilter.join(","),
        keyword: searchKeyword,
        sort: sort,
      }
      try {
        const _users = await getAdminUsers(queryType)
        setUsersDataSource(_users.results)
        if (!usersPageToken.current.length) usersPageToken.current = ["", ..._users.nextPages]
        else if (!usersPageToken.current.includes(_users.nextPages.at(0) ?? "null"))
          usersPageToken.current = [...usersPageToken.current, ..._users.nextPages]
      } catch (e) {
        captureException(e)
      } finally {
        setLoading(false)
      }
    }
    void setAdminUsersAsync()
  }, [
    updateFlag,
    userValues,
    accountTypeFilter,
    permissionGroupFilter,
    searchKeyword,
    sort,
    usersPage,
    pageSize,
    currentPage,
  ])

  const headers = [
    "No.",
    "사용자 계정",
    "이름 / 국가",
    "조직 / 직함",
    "사용자 권한 / 영상 권한",
    "보유 크레딧",
    "누적 사용 크레딧",
    "가입 일시",
    "최근 접속 일시",
    "기타 정보",
  ]
  const columns: ColumnsType<AdminUserResult> = [
    {
      title: headers[0],
      dataIndex: "accountInfo.accountId",
      key: "ID",
      width: "4%",
      sorter: true,
      render: (text, record, index) => <p>{index + 1 + currentPage * pageSize}</p>,
    },
    {
      title: headers[1],
      dataIndex: "accountInfo.profile.email",
      key: "ACCOUNT_KEY",
      width: "18%",
      sorter: true,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt={"LOGO"}
            src={getAccountTypeLogo(record.accountInfo.accountTypes, record.accountInfo.removed)}
            style={{ width: "24px" }}
          />
          <Tooltip title={record.accountInfo.profile.email} placement={"bottom"}>
            <p>{record.accountInfo.profile.email}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      title: headers[2],
      dataIndex: "accountInfo.profile.fullName",
      key: "accountInfo.profile.fullName",
      width: "10%",
      render: (text, record) => (
        <>
          <Tooltip title={record.accountInfo.profile.fullName} placement={"bottom"}>
            <p>{record.accountInfo.profile.fullName || "-"}</p>
          </Tooltip>
          <Tooltip title={record.accountInfo.profile.locale} placement={"bottom"}>
            <p className={styles.subText}>{record.accountInfo.profile.locale || "-"}</p>
          </Tooltip>
        </>
      ),
    },
    {
      title: headers[3],
      dataIndex: "accountInfo.profile.organizationName",
      key: "accountInfo.profile.organizationName",
      width: "10%",
      render: (text, record) => (
        <>
          <Tooltip title={record.accountInfo.profile.organizationName} placement={"bottom"}>
            <p>{record.accountInfo.profile.organizationName || "-"}</p>
          </Tooltip>
          <Tooltip title={record.accountInfo.profile.jobTitle} placement={"bottom"}>
            <p className={styles.subText}>{record.accountInfo.profile.jobTitle || "-"}</p>
          </Tooltip>
        </>
      ),
    },
    {
      title: headers[4],
      dataIndex: "accountInfo.permissions",
      key: "accountInfo.permissions",
      render: (text, record) => {
        const _permissions = getPermissions(record.accountInfo.permissionGroups, permissionGroupRole, true).join(", ")
        return (
          <>
            <Tooltip title={_permissions} placement={"bottom"}>
              <p>{_permissions || "-"}</p>
            </Tooltip>
            <p>{"-"}</p> {/*TODO: 영상 권한*/}
          </>
        )
      },
    },
    {
      title: headers[5],
      dataIndex: "accountInfo.remainCredit",
      key: "REMAIN_CREDIT",
      align: "right",
      width: "9%",
      sorter: true,
      render: (text, record) => <p>{record.accountInfo.remainCredit.toLocaleString()}</p>,
    },
    {
      title: headers[6],
      dataIndex: "accountInfo.usedCredit",
      key: "USED_CREDIT",
      align: "right",
      width: "9%",
      sorter: true,
      render: (text, record) => <p className={styles.primary}>{record.accountInfo.usedCredit.toLocaleString()}</p>,
    },
    {
      title: headers[7],
      dataIndex: "accountInfo.createdTime",
      key: "CREATED_TIME",
      width: "8%",
      sorter: true,
      defaultSortOrder: "descend",
      render: (text, record) => <p>{getDateLabel(record.accountInfo.createdTime, "TIL_SEC")}</p>,
    },
    {
      title: headers[8],
      dataIndex: "accountInfo.lastLogin",
      key: "LAST_LOGIN",
      width: "8%",
      sorter: true,
      render: (text, record) => <p>{getDateLabel(record.accountInfo.lastLogin, "TIL_SEC")}</p>,
    },
    {
      title: headers[9],
      dataIndex: "note",
      key: "note",
      width: "5%",
      render: (text, record) => {
        const handleClick = () => {
          setSelectedRecordNote(record)
          setIsMemoModalVisible(true)
        }
        return (
          <button style={{ cursor: "pointer", background: "initial", border: "initial" }} onClick={handleClick}>
            <Tooltip size={"large"} title={record.note} placement={"bottom"}>
              <DocumentsOutlined
                style={{ color: record.note ? "var(--icon-only)" : "var(--icon-secondary)", fontSize: "20px" }}
              />
            </Tooltip>
          </button>
        )
      },
    },
  ]

  const handleMemoModalSaveClick = () => {
    setNoteLoading(true)
    const putAdminUsersNoteAsync = async () => {
      try {
        await putAdminUsersNote(selectedRecordNote.accountInfo.accountId, { note: selectedRecordNote.note })
        Toast({ message: "수정한 정보를 저장했습니다.", type: "success" })
        setUpdateFlag((prev) => !prev)
      } catch (e) {
        Toast({ message: "수정한 정보를 저장하는데 실패했습니다. 다시 시도해 주세요.", type: "error" })
        captureException(e)
      } finally {
        setNoteLoading(false)
        setIsMemoModalVisible(false)
      }
    }
    void putAdminUsersNoteAsync()
  }

  const clearPagination = () => {
    usersPageToken.current = []
    usersPage.current = ""
    setCurrentPage(0)
  }
  const clearPaginationWithFunction = (func: Function) => {
    clearPagination()
    func()
  }
  const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") return <NavigateBeforeOutlined />
    if (type === "next") return <NavigateNextOutlined />
    return originalElement
  }
  const handleUsersPageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    usersPage.current = usersPageToken.current.at(_page) ?? ""
    setCurrentPage(usersPage.current ? _page : 0)
  }
  const handleShowSizeChange: PaginationProps["onShowSizeChange"] = (current, size) => {
    setPageSize(size)
    clearPagination()
  }
  const handleTableChange: TableProps<AdminUserResult>["onChange"] = (pagination, filters, sorter, extra) => {
    // @NOTE: columns의 각 key를 유효한 sort 값으로 설정
    const _sorter = sorter as SorterResult<AdminUserResult>
    const _order = _sorter.order === "descend" ? "-" : ""
    setSort((_order + _sorter.columnKey) as AdminUserSortType)
  }

  const handleDownloadButtonClick = () => {
    const setAdminUsersAsync = async () => {
      if (!downloadLinkRef.current) return
      const queryType: AdminUserFilterQueryType = {
        pageSize: "1000",
        nextPageCount: "10",
        createdTimeStart: csvRangeValue[0]?.toISOString(),
        createdTimeEnd: csvRangeValue[1]?.toISOString(),
      }
      let _userResults: AdminUserResult[] = []
      try {
        const _users = await getAdminUsers(queryType)
        _userResults = [..._userResults, ..._users.results]
        for await (const nextPage of _users.nextPages) {
          const _nextUsers = await getAdminUsers({ ...queryType, page: nextPage })
          _userResults = [..._userResults, ..._nextUsers.results]
        }

        const csvContent = [
          headers.join(","),
          ..._userResults.map((row, index) =>
            [
              index + 1,
              row.accountInfo.profile.email,
              row.accountInfo.profile.fullName + " / " + row.accountInfo.profile.locale,
              row.accountInfo.profile.organizationName + " / " + row.accountInfo.profile.jobTitle,
              `${getPermissions(row.accountInfo.permissionGroups, permissionGroupRole, true).join(".")}` + " / - ",
              row.accountInfo.remainCredit,
              row.accountInfo.usedCredit,
              getDateLabel(row.accountInfo.createdTime, "TIL_SEC"),
              getDateLabel(row.accountInfo.lastLogin, "TIL_SEC"),
              `${row.note.replaceAll("\r\n", " ").replaceAll("\n", " ")}`,
            ].join(","),
          ),
        ].join("\n")
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
        downloadLinkRef.current.href = URL.createObjectURL(blob)
        downloadLinkRef.current.download = `사용자_관리_${new Date().toLocaleString()}.csv`
        downloadLinkRef.current.click()
        Toast({ message: "다운로드가 완료되었습니다.", type: "success" })
      } catch (e) {
        Toast({ message: "다운로드에 실패했습니다. 다시 시도해 주세요.", type: "error" })
        captureException(e)
      } finally {
        setIsExportCsvModalVisible(false)
      }
    }
    void setAdminUsersAsync()
  }

  return (
    <section className={styles.userManagement}>
      <TableTool
        className={styles.tableTool}
        title={"사용자 관리"}
        downloadLinkRef={downloadLinkRef}
        searchKeyword={searchKeyword}
        selectedUsers={selectedUsers}
        setSearchKeyword={(value) => clearPaginationWithFunction(() => setSearchKeyword(value))}
        setUpdateFlag={setUpdateFlag}
        setUserValues={(value) => clearPaginationWithFunction(() => setUserValues(value))}
        totalInfo={{ label: "전체 사용자", value: `${userStatistics.totalCount}명` }}
        userValues={userValues}
        onDownloadButtonClick={() => setIsExportCsvModalVisible(true)}
      >
        <Select
          options={
            [
              { label: "OVISION Earth", value: "OVISION" },
              { label: "Google", value: "GOOGLE" },
              { label: "SIA", value: "SIA" },
            ] as { label: string; value: AccountType }[]
          }
          maxTagCount={"responsive"}
          mode={"multiple"}
          optionFilterProp={"label"}
          placeholder={"계정 구분"}
          showArrow={true}
          suffixIcon={suffixIcon}
          value={accountTypeFilter}
          onChange={(value) => clearPaginationWithFunction(() => setAccountTypeFilter(value))}
        />
        <Select
          options={
            [
              { label: "일반 사용자", value: "permission-group.role.default" },
              { label: "관리자", value: "permission-group.role.admin" },
              { label: "슈퍼 관리자", value: "permission-group.role.super-admin" },
            ] as { label: Omit<PermissionGroupKoLabel, "시스템">; value: PermissionGroupType }[]
          }
          maxTagCount={"responsive"}
          mode={"multiple"}
          optionFilterProp={"label"}
          placeholder={"사용자 권한"}
          showArrow={true}
          suffixIcon={suffixIcon}
          value={permissionGroupFilter}
          onChange={(value) => clearPaginationWithFunction(() => setPermissionGroupFilter(value))}
        />
        <Select
          disabled={true}
          options={[] as { label: string; value: string }[]}
          placeholder={"영상 권한"}
          suffixIcon={suffixIcon}
        />
      </TableTool>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={usersDataSource}
        loading={{ indicator: <Loading className={styles.loading} size={"small"} />, spinning: loading }}
        pagination={false}
        rowKey={(record) => record.accountInfo.accountId}
        rowSelection={{ type: "checkbox", onChange: (selectedRowKeys, selectedRows) => setSelectedUsers(selectedRows) }}
        scroll={{ y: "100%" }}
        showSorterTooltip={false}
        onChange={handleTableChange}
      />
      <CustomPagination
        current={currentPage + 1}
        itemRender={itemRender}
        pageSize={pageSize}
        total={usersPageToken.current.length * pageSize}
        onChange={handleUsersPageChange}
        onShowSizeChange={handleShowSizeChange}
      />

      {isExportCsvModalVisible && (
        <ExportCsvModal
          setValue={setCsvRangeValue}
          value={csvRangeValue}
          onCloseButtonClick={() => setIsExportCsvModalVisible(false)}
          onDownloadButtonClick={handleDownloadButtonClick}
        />
      )}
      {isMemoModalVisible && (
        <MemoModal
          defaultValue={selectedRecordNote.note}
          disabled={!adminAccountPermissions.accountPermissions.at(0)?.isSuperAdmin}
          loading={noteLoading}
          onChange={(value) => setSelectedRecordNote({ ...selectedRecordNote, note: value })}
          onCloseButtonClick={() => setIsMemoModalVisible(false)}
          onSaveButtonClick={handleMemoModalSaveClick}
        />
      )}
    </section>
  )
}
export default UserManagement
