import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function DocumentsOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 20 20"} {...props}>
      <path
        d={
          "M15.8333 4.16667V11.6667H11.6667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H12.5L17.5 12.5V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM10 11.6667H5.83333V10H10V11.6667ZM14.1667 8.33333H5.83333V6.66667H14.1667V8.33333Z"
        }
      />
    </IconBase>
  )
}
