import { Nullable } from "../../common/common"
import { AccountId } from "../../common/utils/accountUtil"
import { AccountInfo, AccountInfoDto, accountInfoFromDto, initAccountInfo } from "./accountInfoDto"
import { CreditAllResultType, isCreditAllResultTypeValid } from "./creditAllResultsDto"
import { CurrencyType, isCurrencyValid } from "./orderDto"

export type AdminCreditHistoryResult = {
  accountInfo: AccountInfo
  creditHistory: {
    id: string
    causedBy: string
    causedByOnlyId: string // @NOTE: front handling field
    accountId: AccountId
    creditHistoryType: CreditAllResultType
    creditAmount: number
    remainCredit: number
    createdTime: Date | ""
    paid: boolean
    manual: boolean
    price: number
    currency: CurrencyType
    documentExists: boolean // @NOTE: front handling field
    documentName: string // @NOTE: front handling field
  }
}
export type AdminCreditHistoryResults = {
  results: AdminCreditHistoryResult[]
  nextPages: string[]
}

type AdminCreditHistoryResultToNullable = Omit<AdminCreditHistoryResult, "accountInfo">
type AdminCreditHistoryResultDto = {
  accountInfo: AccountInfoDto | null
} & {
  [key in keyof AdminCreditHistoryResultToNullable]: Nullable<AdminCreditHistoryResultToNullable[key]>
}
type AdminCreditHistoryResultsToNullable = Omit<AdminCreditHistoryResults, "results">
export type AdminCreditHistoryResultsDto = {
  results: AdminCreditHistoryResultDto[] | null
} & {
  [key in keyof AdminCreditHistoryResultsToNullable]: Nullable<AdminCreditHistoryResultsToNullable[key]>
}

export type AdminCreditHistoryStatistics = {
  groups: { type: CreditAllResultType; count: number; creditAmount: number }[]
  totalCount: number
}
export type AdminCreditHistoryStatisticsDto = {
  groups: { type: string | null; count: number | null; creditAmount: number | null }[]
  totalCount: number | null
}

export const adminCreditHistoryResultFromDto = (dto: AdminCreditHistoryResultDto): AdminCreditHistoryResult => {
  const _currency = isCurrencyValid(dto.creditHistory?.currency) ? dto.creditHistory?.currency ?? "" : ""
  const _causedByOnlyId = dto.creditHistory?.causedBy.split(":")[1] ?? ""
  return {
    accountInfo: accountInfoFromDto(dto.accountInfo),
    creditHistory: {
      id: dto.creditHistory?.id ?? "",
      causedBy: dto.creditHistory?.causedBy ?? "",
      causedByOnlyId: _causedByOnlyId,
      accountId: dto.creditHistory?.accountId ?? "user:-1",
      creditHistoryType: dto.creditHistory?.creditHistoryType ?? "",
      creditAmount: dto.creditHistory?.creditAmount ?? 0,
      remainCredit: dto.creditHistory?.remainCredit ?? 0,
      createdTime: dto.creditHistory?.createdTime ? new Date(dto.creditHistory.createdTime) : "",
      paid: dto.creditHistory?.paid ?? false,
      manual: dto.creditHistory?.manual ?? false,
      price: dto.creditHistory?.price ?? 0,
      currency: _currency,
      documentExists: false, // @NOTE: Insert value from GET /v2/admin/invoice/documents?invoiceIds
      documentName: "",
    },
  }
}
export const adminCreditHistoryResultsFromDto = (dto: AdminCreditHistoryResultsDto): AdminCreditHistoryResults => {
  return {
    results: dto.results?.map((result) => adminCreditHistoryResultFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}
export const adminCreditHistoryStatisticsFromDto = (dto: AdminCreditHistoryStatisticsDto): AdminCreditHistoryStatistics => {
  return {
    groups: dto.groups.map((group) => {
      const _type = isCreditAllResultTypeValid(group.type) ? group.type : ""
      return { type: _type, count: group.count ?? 0, creditAmount: group.creditAmount ?? 0 }
    }),
    totalCount: dto.totalCount ?? 0,
  }
}

export const initAdminCreditHistoryResult = (): AdminCreditHistoryResult => {
  return {
    accountInfo: initAccountInfo(),
    creditHistory: {
      id: "",
      causedBy: "",
      causedByOnlyId: "",
      accountId: "user:-1",
      creditHistoryType: "",
      creditAmount: 0,
      remainCredit: 0,
      createdTime: "",
      paid: false,
      manual: false,
      price: 0,
      currency: "",
      documentExists: false,
      documentName: "",
    },
  }
}
export const initAdminCreditHistoryResults = (): AdminCreditHistoryResults => {
  return {
    results: [initAdminCreditHistoryResult()],
    nextPages: [],
  }
}
export const initAdminCreditHistoryStatistics = (): AdminCreditHistoryStatistics => {
  return {
    groups: [],
    totalCount: 0,
  }
}

export const isInitialAdminCreditHistoryResult = (adminCreditHistoryResult: AdminCreditHistoryResult) => {
  return adminCreditHistoryResult.creditHistory.id === ""
}
