import { Button, CloseOutlined, DangerButton, ErrorOutlined, IconButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./ConfirmModal.module.scss"

export type ConfirmModalContent = {
  title: string
  desc?: string | React.ReactNode
  confirmText: string
  isPrimaryConfirm?: boolean
  isConfirmButtonLoading?: boolean
  onConfirmButtonClick?: React.MouseEventHandler<HTMLElement>
}
type Props = ConfirmModalContent & {
  className?: string
  isCancelButtonVisible?: boolean
  onCloseButtonClick: React.MouseEventHandler<HTMLElement>
}

function ConfirmModal({ isCancelButtonVisible = true, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.dimmedLayer} />
      <div className={cn(styles.confirmModal, props.className)}>
        <div className={styles.icon}>
          <ErrorOutlined />
        </div>
        <div className={styles.right}>
          <div className={styles.content}>
            <p className={styles.title}>{props.title}</p>
            <p className={styles.desc}>{props.desc}</p>
          </div>
          <div className={styles.footer}>
            {isCancelButtonVisible && (
              <Button className={styles.cancelBtn} size={"small"} type={"outlined"} onClick={props.onCloseButtonClick}>
                {t("button.cancel")}
              </Button>
            )}
            {props.isPrimaryConfirm ? (
              <Button
                className={styles.confirmBtn}
                size={"small"}
                type={"primary"}
                loading={props.isConfirmButtonLoading}
                onClick={props.onConfirmButtonClick}
              >
                {props.confirmText}
              </Button>
            ) : (
              <DangerButton className={styles.confirmBtn} size={"small"} onClick={props.onConfirmButtonClick}>
                {props.confirmText}
              </DangerButton>
            )}
          </div>
        </div>
        <IconButton
          wrapperClassName={styles.closeBtn}
          size={"xs"}
          type={"square"}
          icon={<CloseOutlined />}
          onClick={props.onCloseButtonClick}
        />
      </div>
    </>
  )
}
export default ConfirmModal
