export type SceneStatus = "UPLOADING" | "UPLOADED" | "INVALID" | "TILING" | "COMPLETED" | "ERROR" | "DELETED" | ""
export const isSceneStatusValid = (status: string | null): status is SceneStatus => {
  return (
    status === "UPLOADING" ||
    status === "UPLOADED" ||
    status === "INVALID" ||
    status === "TILING" ||
    status === "COMPLETED" ||
    status === "ERROR" ||
    status === "DELETED"
  )
}

export type Scene = {
  id: string
  name: string
  country: string
  city: string
  continent: string
  gsdX: number
  gsdY: number
  sceneUrl: string
  width: number
  height: number
  fileSize: number
  centroid: string
  boundary: string
  satellite: string
  thumbnail: string
  imagingTime: Date | ""
  createdTime: Date | ""
  status: SceneStatus
  wmsUrl: string
}
export type Scenes = {
  sceneInfoList: Scene[]
  totalCount: number
}

export type SceneDTO = Pick<Scene, "id" | "name"> & {
  country: string | null
  city: string | null
  continent: string | null
  gsdX: number | null
  gsdY: number | null
  sceneUrl: string | null
  width: number | null
  height: number | null
  fileSize: number | null
  centroid: string | null
  boundary: string | null
  satellite: string | null
  thumbnail: string | null
  imagingTime: string | null
  createdTime: string | null
  status: string | null
  wmsUrl: string | null
}
export type ScenesDTO = Omit<Scenes, "sceneInfoList"> & {
  sceneInfoList: SceneDTO[]
}

export type SatelliteDTO = {
  satellites: (string | null)[]
}
export const sceneFromDto = (dto: SceneDTO): Scene => {
  const _status = isSceneStatusValid(dto.status) ? dto.status : ""
  return {
    id: dto.id,
    name: dto.name,
    country: dto.country ?? "",
    city: dto.city ?? "",
    continent: dto.continent ?? "",
    gsdX: dto.gsdX ?? 0,
    gsdY: dto.gsdY ?? 0,
    sceneUrl: dto.sceneUrl ?? "",
    width: dto.width ?? 0,
    height: dto.height ?? 0,
    fileSize: dto.fileSize ?? 0,
    centroid: dto.centroid ?? "",
    boundary: dto.boundary ?? "",
    satellite: dto.satellite ?? "",
    thumbnail: dto.thumbnail ?? "",
    imagingTime: dto.imagingTime ? new Date(dto.imagingTime) : "",
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    status: _status,
    wmsUrl: dto.wmsUrl ?? "",
  }
}
export const scenesFromDto = (dto: ScenesDTO): Scenes => {
  const _sceneInfoList = dto.sceneInfoList.map((scene) => sceneFromDto(scene))

  return {
    sceneInfoList: _sceneInfoList,
    totalCount: dto.totalCount,
  }
}

export const satellitesFromDTO = (dto: SatelliteDTO): string[] => {
  return dto.satellites.map((satellite) => (satellite === null ? "" : satellite))
}

export const initScene = (): Scene => {
  return {
    id: "-1",
    name: "",
    country: "",
    city: "",
    continent: "",
    gsdX: 0,
    gsdY: 0,
    sceneUrl: "",
    width: 0,
    height: 0,
    fileSize: 0,
    centroid: "",
    boundary: "",
    satellite: "",
    thumbnail: "",
    imagingTime: "",
    createdTime: "",
    status: "",
    wmsUrl: "",
  }
}
export const initScenes = (): Scenes => {
  return { sceneInfoList: [], totalCount: 0 }
}

export const isInitialScene = (scene: Scene) => {
  return scene.id === "-1"
}

export type GsdRange = [number | null, number | null]
