import { AiPackInfo, AiPackInfoDTO, aiPackInfoFromDto, initAiPackInfo } from "./aiPackInfosDto"
import { initSource, isSpecificSourceValid, Source, SourceDTO, sourceFromDto, SpecificSourceType } from "./sourceDto"

export type ProjectType = "REALTIME" | "SINGLE" | ""
export type ProjectStatusType = "PROCESSING" | "PAUSED" | "CLOSED" | ""

export const isProjectValid = (project: string | null): project is ProjectType => {
  return project === "REALTIME" || project === "SINGLE"
}
export const isProjectStatusTypeValid = (status: string | null): status is ProjectStatusType => {
  return status === "PROCESSING" || status === "PAUSED" || status === "CLOSED"
}

export type DestinationRequestType = Pick<Destination, "path"> & {
  id: string
}
export type ProjectRequestType = {
  name: string
  type: Extract<ProjectType, "REALTIME" | "SINGLE">
  aoi?: string | null
  aiPackId: number
  source: Required<Pick<Source, "id" | "paths">> & { type: SpecificSourceType }
  destination?: DestinationRequestType
}
export type PutProjectRequestType = {
  id: string
  name?: string
  status: ProjectStatusType
}

export type Destination = {
  syncStorageId: string
  path: string
  type: SpecificSourceType
}

export type Project = {
  id: string
  name: string
  type: ProjectType
  status: ProjectStatusType
  aiPack: AiPackInfo
  aoi: string
  aoiSize: number
  creditAmount: number
  createdTime: Date | ""
  source: Source
  destination: Destination
}
export type Projects = {
  totalCount: number
  projects: Project[]
}

type DestinationDTO = Omit<Destination, "syncStorageId" | "path" | "type"> & {
  syncStorageId: string | null
  path: string | null
  type: string | null
}
type ProjectDTO = Omit<
  Project,
  "type" | "status" | "aiPack" | "aoi" | "aoiSize" | "creditAmount" | "createdTime" | "source" | "destination"
> & {
  type: string
  status: string | null
  aiPack: AiPackInfoDTO
  aoi: string | null
  aoiSize: number | null
  creditAmount: number | null
  createdTime: string
  source: SourceDTO
  destination: DestinationDTO
}
export type ProjectsDTO = Omit<Projects, "projects"> & {
  projects: ProjectDTO[]
}

const destinationFromDto = (dto: DestinationDTO): Destination => {
  const _type = isSpecificSourceValid(dto.type) ? dto.type : ""
  return { syncStorageId: dto.syncStorageId ?? "", path: dto.path ?? "", type: _type }
}
const projectFromDto = (dto: ProjectDTO): Project => {
  const _type = isProjectValid(dto.type) ? dto.type : ""
  const _status = isProjectStatusTypeValid(dto.status) ? dto.status : ""

  return {
    id: dto.id,
    name: dto.name,
    type: _type,
    status: _status,
    aiPack: aiPackInfoFromDto(dto.aiPack),
    aoi: dto.aoi ?? "",
    aoiSize: dto.aoiSize ?? 0,
    creditAmount: dto.creditAmount ?? 0,
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    source: sourceFromDto(dto.source),
    destination: destinationFromDto(dto.destination),
  }
}
export const projectsFromDto = (dto: ProjectsDTO): Projects => {
  const _projects = dto.projects.map((project) => projectFromDto(project))

  return {
    totalCount: dto.totalCount,
    projects: _projects,
  }
}

export const isInitialProject = (project: Project) => {
  return project.id === "-1"
}

export const initProject = (): Project => {
  return {
    id: "-1",
    name: "",
    type: "",
    status: "",
    aiPack: initAiPackInfo(),
    aoi: "",
    aoiSize: 0,
    creditAmount: 0,
    createdTime: "",
    source: initSource(),
    destination: {
      syncStorageId: "",
      path: "",
      type: "",
    },
  }
}
export const initProjects = (): Projects => {
  return { totalCount: 0, projects: [] }
}
