import {
  AdminCreditHistoryResult,
  getAdminInvoiceId,
  getAdminInvoiceIdDocument,
  isInitialAdminCreditHistoryResult,
  Modal,
  patchAdminInvoiceId,
  postAdminInvoiceDocument,
  uploadBySignedUrl,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, InputField, Toast } from "@SIAnalytics/ovision-design-system"
import React, { useCallback, useEffect, useRef, useState } from "react"

import styles from "../GiveCreditModal.module.scss"

type Props = {
  selectedRecordNote: AdminCreditHistoryResult
  setUpdateFlag?: React.Dispatch<React.SetStateAction<boolean>>
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>
}
function InvoiceModal(props: Props) {
  const [viewerLoading, setViewerLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [files, setFiles] = useState<FileList | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const isSaveButtonDisabled = !files?.length

  useEffect(() => {
    return () => {
      setFiles(null)
    }
  }, [])

  const handleViewerButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
    const setAdminInvoiceAsync = async () => {
      if (isInitialAdminCreditHistoryResult(props.selectedRecordNote)) return
      setViewerLoading(true)
      try {
        const documentUrl = await getAdminInvoiceIdDocument(props.selectedRecordNote.creditHistory.causedByOnlyId)
        window.open(documentUrl)
      } catch (e) {
        captureException(e)
      } finally {
        setViewerLoading(false)
        props.onCloseButtonClick?.(e)
      }
    }
    void setAdminInvoiceAsync()
  }
  const handleSaveButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
    const patchAdminInvoiceAsync = async () => {
      if (isInitialAdminCreditHistoryResult(props.selectedRecordNote)) return
      setSaveLoading(true)
      try {
        const file = files?.item(0)
        if (file) {
          // @NOTE: Support single file.
          const _invoice = await getAdminInvoiceId(props.selectedRecordNote.creditHistory.causedByOnlyId)
          const { document } = await postAdminInvoiceDocument(file.name)
          await uploadBySignedUrl(document, file)
          await patchAdminInvoiceId(_invoice.id.toString(), document)
        }
        Toast({ message: "저장이 완료되었습니다.", type: "success" })
        props.setUpdateFlag?.((prev) => !prev)
      } catch (e) {
        Toast({ message: "저장에 실패했습니다. 다시 시도해 주세요.", type: "error" })
        captureException(e)
      } finally {
        setSaveLoading(false)
        props.onCloseButtonClick?.(e)
      }
    }
    void patchAdminInvoiceAsync()
  }

  const fileInputHandler = useCallback((event: Event) => {
    const files = (event.target as HTMLInputElement).files
    if (files) setFiles(files)
  }, [])
  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.addEventListener("change", fileInputHandler)

    return () => {
      inputRef.current?.removeEventListener("change", fileInputHandler)
    }
  }, [inputRef, fileInputHandler])

  return (
    <Modal
      className={styles.giveCreditModal}
      size={"small"}
      title={{
        label: "인보이스",
        right: (
          <Button
            size={"small"}
            disabled={!props.selectedRecordNote.creditHistory.documentExists}
            onClick={handleViewerButtonClick}
          >
            {"뷰어"}
          </Button>
        ),
      }}
      content={
        <div className={styles.vertical}>
          <p>{"인보이스 문서를 첨부하면 업데이트할 수 있어요."}</p>
          <div className={styles.inputGroup}>
            <InputField
              className={styles.invoiceNameField}
              placeholder={"pdf, png, jpg, webp 파일"}
              readOnly={true}
              value={files?.item(0)?.name ?? props.selectedRecordNote.creditHistory.documentName}
            />
            <label className={styles.inputAction} htmlFor={"invoice"}>
              {"파일 선택"}
            </label>
          </div>
          <input
            id={"invoice"}
            ref={inputRef}
            type={"file"}
            accept={".pdf,.png,.jpg,.jpeg,.webp"}
            style={{ display: "none" }}
          />
        </div>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.onCloseButtonClick}>
              {"취소"}
            </Button>
            <Button
              size={"small"}
              type={"primary"}
              disabled={isSaveButtonDisabled}
              loading={saveLoading}
              onClick={handleSaveButtonClick}
            >
              {"저장"}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.onCloseButtonClick}
    />
  )
}
export default InvoiceModal
