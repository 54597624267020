import axios from "axios"
import { getCountryForTimezone } from "countries-and-timezones"
import moment from "moment-timezone"
import { LoaderFunction } from "react-router-dom"

export const loadCookie = (name: string) => {
  const value = document.cookie.match("(^|;) ?" + decodeURIComponent(name) + "=([^;]*)(;|$)")
  return value ? value[2] : null
}

export const saveCookie = (name: string, value: string, expire: number = 1, domain: string = "") => {
  const expiration = new Date()
  expiration.setDate(expiration.getDate() + Number(expire))
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${expiration.toUTCString()};${domain}`
}

export const deleteCookie = (name: string, domain?: string) => {
  if (!document.cookie.includes(name)) return
  saveCookie(name, "", 0, domain)
}

export const changeFirstLetterCase = (str: string, lower: boolean = true) => {
  const firstLetter = lower ? str[0].toLowerCase() : str[0].toUpperCase()
  return str.length > 0 ? firstLetter + str.slice(1, str.length) : str
}

export const changeToCamel = (str: string, firstLower: boolean = true) => {
  return changeFirstLetterCase(
    str.replace(/([-_][a-z])/gi, (replacer) => {
      return replacer.toUpperCase().replace("-", "").replace("_", "")
    }),
    firstLower,
  )
}

type ArrayType = {
  [key: string]: string
}

export const keysToCamel = (object: any): any => {
  if (object === Object(object) && !Array.isArray(object) && typeof object !== "function") {
    const n: ArrayType = {}
    Object.keys(object).forEach((k) => {
      n[changeToCamel(k)] = keysToCamel(object[k])
    })
    return n
  } else if (Array.isArray(object)) {
    return object.map((i) => keysToCamel(i))
  }

  return object
}

export const changeToSnake = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .split(" ")
    .join("_")
    .toLowerCase()
}

export const keysToSnake = (object: any): any => {
  if (object === Object(object) && !Array.isArray(object) && typeof object !== "function") {
    const n: ArrayType = {}
    Object.keys(object).forEach((k) => {
      n[changeToSnake(k)] = keysToSnake(object[k])
    })
    return n
  } else if (Array.isArray(object)) {
    return object.map((i) => keysToSnake(i))
  } else if (object === undefined || object === null) {
    return null
  }

  return object
}

export const decodeThumbnail = (thumbnail?: string | null) => {
  return thumbnail ? "data:image/png;base64, " + thumbnail : ""
}
export const getFormattedGsd = (gsdX?: number | null, gsdY?: number | null) => {
  if (gsdX && gsdY) return `${Math.abs(gsdX).toFixed(1)} x ${Math.abs(gsdY).toFixed(1)}m`
  else return "-"
}
export const getFormattedArea = (area: number, toNumber: boolean = false) => {
  const fixed = Math.ceil(area * 100) / 100
  return toNumber ? fixed : area === 0 ? "-" : fixed + " km²"
}

export type LoaderData<TLoaderFn extends LoaderFunction> = Awaited<ReturnType<TLoaderFn>> extends Response | infer D
  ? D
  : never
export type Nullable<T> = T extends (infer U)[] ? (U | null)[] : T | null
export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null
}

export function fetchRetry(url: string, tries: number = 3) {
  function onError(err: any): any {
    const triesLeft = tries - 1
    if (!triesLeft) throw err

    return fetchRetry(url, triesLeft)
  }
  return fetch(url).catch(onError)
}

export const encodeToBase64 = (data: string) => {
  return Buffer.from(data).toString("base64")
}

export const decodeFromBase64 = (data: string) => {
  return Buffer.from(data, "base64").toString("utf8")
}

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1]
  const jsonPayload = decodeFromBase64(base64Url)

  return JSON.parse(jsonPayload)
}

export const getUserCountry = () => {
  return getCountryForTimezone(moment.tz.guess())
}

export async function uploadBySignedUrl(sessionURI: string, file: File, type?: string) {
  await axios(sessionURI, {
    method: "PUT",
    data: file,
    headers: {
      "Content-Type": type || file.type,
    },
  })
}
