import { Nullable } from "../../common/common"
import { AiPackInfo, AiPackInfoDTO, aiPackInfoFromDto, initAiPackInfo } from "./aiPackInfosDto"

export type AnalysisStatusType =
  | "IDLE"
  | "SCENE_DOWNLOADING"
  | "SCENE_DOWNLOADED"
  | "SCHEDULE_WAITING"
  | "PROCESSING"
  | "POSTPROCESSING"
  | "COMPLETED"
  | "ERROR"
  | "SCENE_DOWNLOAD_ERROR"
  | ""
export const isAnalysisStatusValid = (status: string | null): status is AnalysisStatusType => {
  return (
    status === "IDLE" ||
    status === "SCENE_DOWNLOADING" ||
    status === "SCENE_DOWNLOADED" ||
    status === "SCHEDULE_WAITING" ||
    status === "PROCESSING" ||
    status === "POSTPROCESSING" ||
    status === "COMPLETED" ||
    status === "ERROR" ||
    status === "SCENE_DOWNLOAD_ERROR"
  )
}

export type InferenceJobInfo = {
  id: string
  jobName: string
  aiPackInfo: AiPackInfo
  regionName: string
  aoiSize: number
  totalDuration?: string
  eta: number
  status: AnalysisStatusType
  createdTime: Date | ""
  imagingTime: (Date | "")[]
  creditAmount: number
  sourceTypeList: string[]
  gsdX: number[]
  gsdY: number[]
  satellite: string[]
  thumbnail: string[]
}
export type InferenceJobInfos = {
  totalCount: number
  inferenceJobInfoList: InferenceJobInfo[]
}

type InferenceJobInfoToNullable = Omit<InferenceJobInfo, "id" | "aiPackInfo">
type InferenceJobInfoDTO = Pick<InferenceJobInfo, "id"> & {
  aiPackInfo: AiPackInfoDTO
} & {
  [key in keyof InferenceJobInfoToNullable]: Nullable<InferenceJobInfoToNullable[key]>
}
export type InferenceJobInfosDTO = Omit<InferenceJobInfos, "inferenceJobInfoList"> & {
  inferenceJobInfoList: InferenceJobInfoDTO[]
}

export const inferenceJobInfoFromDto = (dto: InferenceJobInfoDTO): InferenceJobInfo => {
  const _status = isAnalysisStatusValid(dto.status) ? dto.status : ""
  return {
    id: dto.id,
    jobName: dto.jobName ?? "",
    aiPackInfo: aiPackInfoFromDto(dto.aiPackInfo),
    regionName: dto.regionName ?? "",
    aoiSize: dto.aoiSize ?? 0,
    totalDuration: dto.totalDuration ?? "",
    eta: dto.eta ?? 0,
    status: _status,
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    imagingTime: dto.imagingTime.map((_imagingTime) => (_imagingTime ? new Date(_imagingTime) : "")),
    creditAmount: dto.creditAmount ?? 0,
    sourceTypeList: dto.sourceTypeList.map((_sourceType) => _sourceType ?? ""),
    gsdX: dto.gsdX.map((_gsdX) => _gsdX ?? 0),
    gsdY: dto.gsdY.map((_gsdY) => _gsdY ?? 0),
    satellite: dto.satellite.map((_satellite) => _satellite ?? ""),
    thumbnail: dto.thumbnail.map((_thumbnail) => _thumbnail ?? ""),
  }
}
export const inferenceJobInfosFromDto = (dto: InferenceJobInfosDTO): InferenceJobInfos => {
  const _inferenceJobInfoList = dto.inferenceJobInfoList.map((inferenceJobInfo) => inferenceJobInfoFromDto(inferenceJobInfo))

  return {
    totalCount: dto.totalCount,
    inferenceJobInfoList: _inferenceJobInfoList,
  }
}

export const isInitialInferenceJobInfo = (job: InferenceJobInfo) => {
  return job.id === "-1"
}

export const initInferenceJobInfo = (): InferenceJobInfo => {
  return {
    id: "-1",
    jobName: "",
    aiPackInfo: initAiPackInfo(),
    regionName: "",
    aoiSize: 0,
    totalDuration: "",
    eta: 0,
    status: "IDLE",
    createdTime: "",
    imagingTime: [""],
    creditAmount: 0,
    sourceTypeList: [],
    gsdX: [0],
    gsdY: [0],
    satellite: [""],
    thumbnail: [""],
  }
}
export const initInferenceJobInfos = (): InferenceJobInfos => {
  return {
    totalCount: 0,
    inferenceJobInfoList: [],
  }
}
