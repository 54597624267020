import { captureException } from "@sentry/react"
import { Button, Checkbox, Dropdown, InputField, Toast } from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import cn from "classnames"
import { countries, getCountryCode, ICountry, TCountryCode } from "countries-list"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import {
  ACCESS_TOKEN_KEY,
  OE_REDIRECT_PATH,
  PATH_END_USER_LICENSE_AGREEMENT,
  PATH_HOME,
  PATH_PRIVACY_POLICY,
  PATH_SIGN_IN,
  PATH_TERMS_AND_CONDITIONS,
  REFRESH_TOKEN_KEY,
} from "../../.."
import { patchProfile, postPromotions, postRefreshToken } from "../../common/axiosApi"
import { getUserCountry } from "../../common/common"
import styles from "./SignUp.module.scss"

type FormContent = "LAST_NAME" | "JOB_TITLE" | "NAME_OF_ORGANIZATION" | "PROMOTION_CODE"

function SignInAddInfo() {
  const [countryDatas, setCountryDatas] = useState<[TCountryCode, ICountry][]>([])
  const [countryDropdownItems, setCountryDropdownItems] = useState<ItemOption[]>([])
  const [countryCode, setCountryCode] = useState<TCountryCode | "">("")
  const [countryNative, setCountryNative] = useState<string>("")
  const [jobTitle, setJobTitle] = useState<string>("")
  const [isJobTitleValid, setIsJobTitleValid] = useState<InputFieldBasicStateType>("default")
  const [organizationName, setOrganizationName] = useState<string>("")
  const [isOrganizationNameValid, setIsOrganizationNameValid] = useState<InputFieldBasicStateType>("default")
  const [promotionCode, setPromotionCode] = useState<string>("")
  const [isPromotionCodeValid, setIsPromotionCodeValid] = useState<InputFieldBasicStateType>("default")
  const [checkAgree, setCheckAgree] = useState<boolean>(false)
  const [isCheckAgreeValid, setIsCheckAgreeValid] = useState<boolean>(true)
  const [checkCertify, setCheckCertify] = useState<boolean>(false)
  const [isCheckCertifyValid, setIsCheckCertifyValid] = useState<boolean>(true)
  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const legalCheckboxRef = useRef<HTMLInputElement>(null)
  const certifyCheckboxRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const countryDropdownItemsMemo: ItemOption[] = useMemo(() => {
    setCountryDatas(Object.entries(countries) as [TCountryCode, ICountry][])
    return Object.entries(countries).map((_data) => {
      return { text: _data[1].native, value: _data[1].native }
    })
  }, [])

  const isSignUpDisabled = !countryCode || !jobTitle || !organizationName || !checkAgree || !checkCertify

  useEffect(() => {
    setCountryDropdownItems(countryDropdownItemsMemo)
    const countryId = getUserCountry()?.id
    const _data = countryId ? countryDatas.filter((_country) => _country[0] === countryId) : []
    if (_data.length) {
      setCountryCode(_data[0][0])
      setCountryNative(_data[0][1].native)
    }

    return () => {
      setCountryCode("")
      setCountryNative("")
      setJobTitle("")
      setIsJobTitleValid("default")
      setOrganizationName("")
      setIsOrganizationNameValid("default")
      setPromotionCode("")
      setIsPromotionCodeValid("default")
      setCheckAgree(false)
      setIsCheckAgreeValid(true)
      setCheckCertify(false)
      setIsCheckCertifyValid(true)
      setIsRequesting(false)
    }
  }, [countryDatas, countryDropdownItemsMemo, t])

  const onChange = (target: FormContent, e: React.ChangeEvent<HTMLInputElement>) => {
    switch (target) {
      case "JOB_TITLE":
        setIsJobTitleValid("default")
        setJobTitle(e.target.value)
        return
      case "NAME_OF_ORGANIZATION":
        setIsOrganizationNameValid("default")
        setOrganizationName(e.target.value)
        return
      case "PROMOTION_CODE":
        setIsPromotionCodeValid("default")
        setPromotionCode(e.target.value)
        return
      default:
        return
    }
  }

  const handleGetStartedClick = () => {
    setIsRequesting(true)

    setIsCheckAgreeValid(checkAgree)
    setIsCheckCertifyValid(checkCertify)

    const patchProfileAsync = async () => {
      const data = {
        countryCode,
        jobTitle,
        organizationName,
      }
      try {
        if (promotionCode) await postPromotions(promotionCode)
        await patchProfile(data)

        if (localStorage.refreshToken) {
          const { accessToken, refreshToken } = await postRefreshToken(localStorage.refreshToken)

          localStorage[ACCESS_TOKEN_KEY] = accessToken
          localStorage[REFRESH_TOKEN_KEY] = refreshToken
        }

        const redirectUrl = location.state ? location.state[OE_REDIRECT_PATH] : null
        navigate(redirectUrl ?? PATH_HOME, { replace: true })
      } catch (e) {
        const res = e as { status: number; data: any; url: string | undefined }

        //TODO:: error 객체에 error의 출처를 알 수 있는 필드 추가하기
        if (res.url === "/v2/my-account/promotions" && res.status === 404) {
          setIsPromotionCodeValid("error")
        } else {
          Toast({ message: t("toast.signIn.error"), type: "error" })
          navigate(PATH_SIGN_IN, { replace: true })
          captureException(e)
        }
      } finally {
        setIsRequesting(false)
      }
    }

    void patchProfileAsync()
  }

  return (
    <div className={styles.signUp}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <p>{t("signUpAddInfo.title")}</p>
        </div>
        <form
          onKeyDown={(e) => {
            if ((e.code === "Enter" || e.code === "Space") && document.activeElement?.id === "legalCheckboxLabel")
              legalCheckboxRef.current?.click()
            else if ((e.code === "Enter" || e.code === "Space") && document.activeElement?.id === "certifyCheckboxLabel")
              certifyCheckboxRef.current?.click()
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          <Dropdown
            size={"large"}
            type={"single"}
            itemList={countryDropdownItems}
            labelText={t("textInput.country.title") ?? ""}
            placeholder={t("dropdown.placeholder.title", { target: t("textInput.country.title").toLowerCase() }) ?? ""}
            search={true}
            searchPlaceholder={t("dropdown.searchPlaceholder.title") ?? ""}
            value={countryNative}
            onChange={(e) => {
              const _native = e as string
              const _code = getCountryCode(_native)
              if (_code) {
                setCountryCode(_code)
                setCountryNative(_native)
              }
            }}
          />
          <InputField
            id={"jobTitle"}
            tabIndex={6}
            size={"large"}
            type={"text"}
            autoComplete={"organization-title"}
            label={t("textInput.jobTitle") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.jobTitle").toLowerCase() }) ?? ""}
            state={isJobTitleValid}
            onChange={(e) => onChange("JOB_TITLE", e)}
            onClearButtonClick={() => setJobTitle("")}
          />
          <InputField
            id={"organization"}
            tabIndex={7}
            size={"large"}
            type={"text"}
            autoComplete={"organization"}
            label={t("textInput.nameOfOrganization") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.nameOfOrganization").toLowerCase() }) ?? ""}
            state={isOrganizationNameValid}
            onChange={(e) => onChange("NAME_OF_ORGANIZATION", e)}
            onClearButtonClick={() => setOrganizationName("")}
          />
          <InputField
            id={"promotionCode"}
            labelClassName={"ods-input-optional"}
            tabIndex={8}
            size={"large"}
            type={"text"}
            autoComplete={"off"}
            helperText={(isPromotionCodeValid === "error" ? t("textInput.promotionCode.helperText.incorrect") : "") ?? ""}
            label={t("textInput.promotionCode.title") + " "}
            placeholder={t("textInput.placeholder", { target: t("textInput.promotionCode.title").toLowerCase() }) ?? ""}
            state={isPromotionCodeValid}
            onChange={(e) => onChange("PROMOTION_CODE", e)}
            onClearButtonClick={() => setPromotionCode("")}
          />
          <div className={styles.checkboxGroup}>
            <Checkbox
              className={cn(styles.checkbox, !isCheckAgreeValid && styles.required)}
              id={"legalCheckbox"}
              ref={legalCheckboxRef}
              tabIndex={9}
              active={checkAgree}
              onClick={() => setCheckAgree((prev) => !prev)}
            >
              <p>
                <Trans i18nKey={"checkbox.agreeToLegal.label"}>
                  I agree to the
                  <a tabIndex={-1} href={PATH_TERMS_AND_CONDITIONS} rel={"noopener noreferrer"} target={"_blank"}>
                    Terms
                  </a>
                  ,
                  <a tabIndex={-1} href={PATH_PRIVACY_POLICY} rel={"noopener noreferrer"} target={"_blank"}>
                    Privacy
                  </a>
                  and
                  <a tabIndex={-1} href={PATH_END_USER_LICENSE_AGREEMENT} rel={"noopener noreferrer"} target={"_blank"}>
                    EULA
                  </a>
                  .{{ terms: t("termsAndConditions.label") }}
                  {{ privacy: t("privacyPolicy.title") }}
                  {{ eula: t("eula.abbreviation.title") }}
                </Trans>
              </p>
            </Checkbox>
            <Checkbox
              className={cn(styles.checkbox, !isCheckCertifyValid && styles.required)}
              id={"certifyCheckbox"}
              ref={certifyCheckboxRef}
              tabIndex={10}
              active={checkCertify}
              onClick={() => setCheckCertify((prev) => !prev)}
            >
              <p>{t("checkbox.certifyOfAge.title")}</p>
            </Checkbox>
          </div>
          <Button
            className={styles.signUpBtn}
            size={"xl"}
            type={"cta"}
            disabled={isSignUpDisabled}
            htmlType={"submit"}
            loading={isRequesting}
            onClick={handleGetStartedClick}
          >
            {t("button.getStarted")}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default SignInAddInfo
