import {
  AdminAccountPermissions,
  AdminAccountPermissionsRequestType,
  AdminUserAutoComplete,
  AdminUserResult,
  ConfirmModal,
  getAdminPermissionsAccounts,
  getAdminUsersAutoComplete,
  getPermissionKoLabel,
  initAdminAccountPermissions,
  LoaderData,
  Loading,
  PermissionGroup,
  PermissionGroupKoLabel,
  permissionGroupRoleAdmin,
  permissionGroupRoleDefault,
  permissionGroupRoleSuperAdmin,
  permissionGroupRoleSystem,
  PermissionGroupType,
  putAdminPermissionAccounts,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  Button,
  FileDownloadOutlined,
  InputField,
  NavigateDownOutlined,
  SearchOutlined,
  Toast,
} from "@SIAnalytics/ovision-design-system"
import { Dropdown, Menu, MenuProps, Select } from "antd"
import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useRouteLoaderData } from "react-router-dom"

import GiveCredit from "./GiveCredit"
import { HomeLoader } from "./home/Home"
import styles from "./TableTool.module.scss"

type Props = {
  className?: string
  title: string
  userValues: string[]
  searchKeyword: string
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>
  setUserValues: React.Dispatch<React.SetStateAction<string[]>>
  selectedUsers?: AdminUserResult[]
  setUpdateFlag?: React.Dispatch<React.SetStateAction<boolean>>
  totalInfo: { label: string; value: string }
  downloadLinkRef?: React.MutableRefObject<HTMLAnchorElement | null>
  children?: React.ReactNode
  onDownloadButtonClick?: React.MouseEventHandler<HTMLElement>
}

function TableTool(props: Props) {
  const { user } = useRouteLoaderData("home") as LoaderData<typeof HomeLoader>
  const [adminAccountPermissions, setAdminAccountPermissions] =
    useState<AdminAccountPermissions>(initAdminAccountPermissions)

  const [data, setData] = useState<AdminUserAutoComplete[]>([])
  const [limit, setLimit] = useState<number>(10)
  const [hasMore, setHasMore] = useState(true)
  const [keyword, setKeyword] = useState<string>("")
  const [suffixIcon, setSuffixIcon] = useState<React.ReactNode>(<NavigateDownOutlined />)

  const [currentSearchKeyword, setCurrentSearchKeyword] = useState<string>(props.searchKeyword)

  const [grantPermission, setGrantPermission] = useState<PermissionGroupType>(permissionGroupRoleDefault)

  const [isGiveCreditModalVisible, setIsGiveCreditModalVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  let timeout: ReturnType<typeof setTimeout> | null
  let currentValue: string

  const handleScroll: React.UIEventHandler<HTMLDivElement> = async (event) => {
    const target = event.target as HTMLDivElement
    if (target.scrollTop + target.clientHeight >= target.scrollHeight && hasMore && !loading) setLimit((prev) => prev + 10)
  }

  const debounceFetcher = (value: string = keyword, callback: Function = setDataCallback) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value

    const setAdminUserAutoCompletesAsync = async () => {
      setLoading(true)
      try {
        const _userAutoCompletes = await getAdminUsersAutoComplete({ keyword: value || "@", limit: `${limit}` })
        if (currentValue === value) callback(value, _userAutoCompletes.accounts)
      } catch (e) {
        captureException(e)
      } finally {
        setLoading(false)
      }
    }

    timeout = setTimeout(setAdminUserAutoCompletesAsync, 300)
  }
  const setDataCallback = (keyword: string, data: AdminUserAutoComplete[]) => {
    setKeyword(keyword)
    setData(data)
    setHasMore(data.length > 0)
  }

  const handleSearchAccountChange = (value: string[]) => {
    if (!value.length) debounceFetcher("")
    props.setUserValues(value)
  }
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setGrantPermission(e.key as PermissionGroupType)
    setIsConfirmModalVisible(true)
  }
  const permissionGroupItems: MenuProps["items"] = [
    { label: "일반 사용자", key: "permission-group.role.default" },
    { label: "관리자", key: "permission-group.role.admin" },
    { label: "슈퍼 관리자", key: "permission-group.role.super-admin" },
  ] as { label: Omit<PermissionGroupKoLabel, "시스템">; key: PermissionGroupType }[]

  const getPermissionLabel = () => {
    let _permission: PermissionGroup = "Default"
    if (grantPermission === permissionGroupRoleAdmin) _permission = "Admin"
    else if (grantPermission === permissionGroupRoleSuperAdmin) _permission = "SuperAdmin"
    else if (grantPermission === permissionGroupRoleSystem) _permission = "System"
    return getPermissionKoLabel(_permission)
  }
  const handleConfirmButtonClick = () => {
    const putAdminPermissionAccountsAsync = async () => {
      setLoading(true)
      const accountsIds = props.selectedUsers?.map((users) => users.accountInfo.accountId).join(",")
      const _permissionGroups: PermissionGroupType[] = [grantPermission]
      if (!accountsIds) return
      try {
        const _currentPermission = await getAdminPermissionsAccounts(accountsIds)
        const data: AdminAccountPermissionsRequestType = {
          accountPermissions: _currentPermission.accountPermissions.map((permission) => ({
            accountId: permission.accountId,
            permissions: permission.permissions,
            permissionGroups: _permissionGroups,
          })),
        }
        const _newPermission = await putAdminPermissionAccounts(data)
        Toast({ message: "권한을 부여했습니다.", type: "success" })
        props.setUpdateFlag?.((prev) => !prev)
      } catch (e) {
        Toast({ message: "권한을 부여하는데 실패했습한니다. 다시 시도해 주세요.", type: "error" })
        captureException(e)
      } finally {
        setLoading(false)
        setIsConfirmModalVisible(false)
      }
    }
    void putAdminPermissionAccountsAsync()
  }
  const handleSearchInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") props.setSearchKeyword(currentSearchKeyword)
  }

  useEffect(() => {
    const setAdminAccountPermissionsAsync = async () => {
      try {
        const _permissions = await getAdminPermissionsAccounts(user.account.id)
        setAdminAccountPermissions(_permissions)
      } catch (e) {
        captureException(e)
      }
    }
    void setAdminAccountPermissionsAsync()
  }, [])
  useEffect(() => {
    debounceFetcher()
  }, [limit])

  return (
    <div className={cn(styles.tableTool, props.className)}>
      <p className={styles.title}>{props.title}</p>
      <div className={styles.filterContainer}>
        <Select
          maxTagCount={"responsive"}
          mode={"multiple"}
          notFoundContent={loading ? <Loading size={"xs"} /> : null}
          optionFilterProp={"label"}
          options={data.map((d) => ({ label: d.profile.email, value: d.accountId }))}
          placeholder={"사용자 계정"}
          showArrow={true}
          showSearch={true}
          suffixIcon={suffixIcon}
          value={props.userValues}
          onBlur={(e) => debounceFetcher("")}
          onChange={handleSearchAccountChange}
          onDropdownVisibleChange={(open) => setSuffixIcon(open ? <SearchOutlined /> : <NavigateDownOutlined />)}
          onPopupScroll={handleScroll}
          onSearch={(value) => debounceFetcher(value)}
        />

        {props.children}

        <div className={styles.searchContainer}>
          <InputField
            placeholder={"검색어를 입력해주세요"}
            value={currentSearchKeyword}
            onChange={(e) => setCurrentSearchKeyword(e.target.value)}
            onClearButtonClick={() => setCurrentSearchKeyword("")}
            onKeyDown={handleSearchInputKeyDown}
          />
          <Button onClick={() => props.setSearchKeyword(currentSearchKeyword)}>{"검색"}</Button>
        </div>
      </div>
      <div className={styles.tableTotalInfo}>
        <div className={styles.left}>
          <span>{props.totalInfo.label}</span>
          <span className={styles.highlightInfo}>{props.totalInfo.value}</span>
        </div>
        <div className={styles.right}>
          {adminAccountPermissions.accountPermissions.at(0)?.isSuperAdmin && props.selectedUsers && (
            <>
              <Dropdown
                disabled={!props.selectedUsers.length}
                overlay={<Menu items={permissionGroupItems} onClick={handleMenuClick} />}
                trigger={["click"]}
              >
                <Button size={"small"} action={true}>
                  {"사용자 권한 부여"}
                </Button>
              </Dropdown>
              <Button
                size={"small"}
                disabled={!props.selectedUsers.length}
                onClick={() => setIsGiveCreditModalVisible(true)}
              >
                {"크레딧 부여"}
              </Button>
            </>
          )}
          <Button size={"small"} type={"outlined"} icon={<FileDownloadOutlined />} onClick={props.onDownloadButtonClick}>
            {"다운로드"}
          </Button>
          <a ref={props.downloadLinkRef} href={"#"} />
        </div>
      </div>

      {props.selectedUsers && (
        <GiveCredit
          isGiveCreditModalVisible={isGiveCreditModalVisible}
          selectedUsers={props.selectedUsers}
          setIsGiveCreditModalVisible={setIsGiveCreditModalVisible}
          setUpdateFlag={props.setUpdateFlag}
        />
      )}
      {isConfirmModalVisible && props.selectedUsers && (
        <ConfirmModal
          title={`${props.selectedUsers.length}개의 계정에 ${getPermissionLabel()} 권한을 부여하시겠습니까?`}
          confirmText={"승인"}
          isConfirmButtonLoading={loading}
          isPrimaryConfirm={true}
          onCloseButtonClick={() => setIsConfirmModalVisible(false)}
          onConfirmButtonClick={handleConfirmButtonClick}
        />
      )}
    </div>
  )
}

export default TableTool
