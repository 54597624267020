import { CloseOutlined, IconButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"

import styles from "./Modal.module.scss"

type Props = {
  className?: string
  title: string | { label: string; right: React.ReactNode } | null
  size?: "default" | "small"
  content?: React.ReactNode
  footer?: {
    left?: React.ReactNode
    right?: React.ReactNode
  }
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>
}

function Modal(props: Props) {
  return (
    <>
      <div className={styles.dimmedLayer} />
      <div className={cn(styles.modal, props.size === "small" && styles.small, props.className)}>
        <header>
          <span className={styles.title}>{typeof props.title === "string" ? props.title : props.title?.label}</span>
          <div className={styles.right}>
            {typeof props.title !== "string" && props.title?.right}
            <IconButton size={"small"} type={"square"} icon={<CloseOutlined />} onClick={props.onCloseButtonClick} />
          </div>
        </header>
        <section className={styles.content}>{props.content}</section>
        <footer>
          <div className={styles.left}>{props.footer?.left}</div>
          <div className={styles.right}>{props.footer?.right}</div>
        </footer>
      </div>
    </>
  )
}
export default Modal
