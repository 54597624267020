import { Nullable } from "../../common/common"
import { AccountId } from "../../common/utils/accountUtil"
import { initProfile, Profile, profileFromDto } from "./ProfileDto"

export type AdminUserAutoComplete = {
  accountId: AccountId
  key: string
  profile: Profile
  removed: boolean
  signInMetadata: {
    lastLogin: Date | ""
  }
}
export type AdminUserAutoCompletes = {
  accounts: AdminUserAutoComplete[]
}

type AdminUserAutoCompleteToNullable = Omit<AdminUserAutoComplete, "signInMetadata">
type AdminUserAutoCompleteDto = {
  signInMetadata: {
    lastLogin: string | null
  }
} & {
  [key in keyof AdminUserAutoCompleteToNullable]: Nullable<AdminUserAutoCompleteToNullable[key]>
}
export type AdminUserAutoCompletesDto = {
  accounts: AdminUserAutoCompleteDto[] | null
}

export const adminUserAutoCompleteFromDto = (dto: AdminUserAutoCompleteDto): AdminUserAutoComplete => {
  return {
    accountId: dto.accountId ?? "user:-1",
    key: dto.key ?? "",
    profile: profileFromDto(dto.profile),
    removed: dto.removed ?? false,
    signInMetadata: {
      lastLogin: dto.signInMetadata.lastLogin ? new Date(dto.signInMetadata.lastLogin) : "",
    },
  }
}
export const adminUserAutoCompletesFromDto = (dto: AdminUserAutoCompletesDto): AdminUserAutoCompletes => {
  return {
    accounts: dto.accounts?.map((account) => adminUserAutoCompleteFromDto(account)) ?? [],
  }
}

export const initAdminUserAutoComplete = (): AdminUserAutoComplete => {
  return {
    accountId: "user:-1",
    key: "",
    profile: initProfile(),
    removed: false,
    signInMetadata: {
      lastLogin: "",
    },
  }
}
