import { CreditAllResultType } from "../../hooks/data/creditAllResultsDto"
import { CurrencyType } from "../../hooks/data/orderDto"

export const getCurrencyLabel = (type?: CurrencyType) => {
  switch (type) {
    case "USD":
      return "$"
    case "KRW":
      return "₩"
    default:
      return ""
  }
}

export const getCreditAllTypeKoLabel = (type: CreditAllResultType) => {
  if (type === "RECHARGE") return "충전"
  else if (type == "USAGE") return "사용"
  else if (type == "EXPIRATION") return "만료"
  else if (type == "REFUND") return "환불"
  else if (type == "COMPENSATION") return "보상"
}
export const getGrantCreditFlagKoLabel = (flag: { paid: boolean; manual: boolean }) => {
  return ` (${flag.paid ? "유료" : "무료"}${flag.manual ? "• 수동)" : ")"}`
}
