import { Modal } from "@ovision-gis-frontend/shared"
import { Button, TextArea } from "@SIAnalytics/ovision-design-system"
import React from "react"

type Props = {
  defaultValue?: string
  loading?: boolean
  disabled?: boolean
  onChange: (value: string) => void
  onCloseButtonClick: React.MouseEventHandler<HTMLElement>
  onSaveButtonClick: React.MouseEventHandler<HTMLElement>
}
function MemoModal(props: Props) {
  return (
    <Modal
      size={"small"}
      title={"기타 정보"}
      content={
        <TextArea
          defaultValue={props.defaultValue}
          maxLength={500}
          placeholder={"정보를 입력해 주세요."}
          resizer={true}
          onChange={props.onChange}
        />
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.onCloseButtonClick}>
              {"취소"}
            </Button>
            <Button
              size={"small"}
              type={"primary"}
              disabled={props.disabled}
              loading={props.loading}
              onClick={props.onSaveButtonClick}
            >
              {"저장"}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.onCloseButtonClick}
    />
  )
}
export default MemoModal
