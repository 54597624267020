import {
  ProfileMenu,
  MyAccount,
  postSignOut,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  PATH_SIGN_IN,
  PATH_USER_MANAGEMENT,
  PATH_CREDIT_MANAGEMENT,
} from "@ovision-gis-frontend/shared"
import OvisionLogoImg from "@ovision-gis-frontend/shared/src/asset/logo/OvisionLogo.png"
import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import styles from "./SideNavigationBar.module.scss"

type Props = {
  className?: string
  user: MyAccount
}

export default function SideNavigationBar(props: Props) {
  const activeClassName = styles.active
  const navigate = useNavigate()

  const onLogoutBtnClick = () => {
    const logoutAsync = async () => {
      try {
        await postSignOut()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        navigate(PATH_SIGN_IN, { replace: true })
      } catch (e) {
        Toast({ message: "로그아웃에 실패하였습니다.", type: "error" })
        captureException(e)
      }
    }
    void logoutAsync()
  }

  return (
    <nav className={cn(styles.sideNavigationBar, props.className)}>
      <div className={styles.logoWrapper}>
        <NavLink className={styles.logoLink} to={PATH_USER_MANAGEMENT}>
          <img className={styles.logo} alt={""} src={OvisionLogoImg} />
          <span className={styles.logoMainText}>{"OVISION"}</span>
          <span className={styles.logoSubText}>{"Earth"}</span>
        </NavLink>
      </div>
      <ProfileMenu className={styles.profileMenu} user={props.user} onLogoutBtnClick={onLogoutBtnClick} />
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_USER_MANAGEMENT}>
        {"사용자 관리"}
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_CREDIT_MANAGEMENT}>
        {"크레딧 관리"}
      </NavLink>
    </nav>
  )
}
