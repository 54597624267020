import { captureException } from "@sentry/react"
import {
  ArrowBackOutlined,
  Button,
  Checkbox,
  Dropdown,
  IconButton,
  InputField,
  Toast,
} from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import {
  InputFieldBasicStateType,
  InputFieldPasswordStateType,
} from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import { AxiosResponse } from "axios"
import cn from "classnames"
import { countries, getCountryCode, ICountry, TCountryCode, getCountryData } from "countries-list"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

import {
  emailReg,
  MemberRequestType,
  OE_EMAIL,
  passwordReg,
  PATH_END_USER_LICENSE_AGREEMENT,
  PATH_ONBOARDING,
  PATH_PRIVACY_POLICY,
  PATH_SIGN_IN,
  PATH_TERMS_AND_CONDITIONS,
  postSignUp,
} from "../../.."
import { getUserCountry } from "../../common/common"
import styles from "./SignUp.module.scss"

type FormContent =
  | "EMAIL"
  | "PASSWORD"
  | "CONFIRM_PASSWORD"
  | "FIRST_NAME"
  | "LAST_NAME"
  | "JOB_TITLE"
  | "NAME_OF_ORGANIZATION"
  | "PROMOTION_CODE"
type EmailError = "INCORRECT" | "REGISTERED" | ""

type Props = {}

function SignUp(props: Props) {
  const [email, setEmail] = useState<string>("")
  const [isEmailValid, setIsEmailValid] = useState<InputFieldBasicStateType>("default")
  const [emailError, setEmailError] = useState<EmailError>("")
  const [password, setPassword] = useState<string>("")
  const [isPasswordValid, setIsPasswordValid] = useState<InputFieldPasswordStateType>("default")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<InputFieldPasswordStateType>("default")
  const [firstName, setFirstName] = useState<string>("")
  const [isFirstNameValid, setIsFirstNameValid] = useState<InputFieldBasicStateType>("default")
  const [lastName, setLastName] = useState<string>("")
  const [isLastNameValid, setIsLastNameValid] = useState<InputFieldBasicStateType>("default")
  const [countryCode, setCountryCode] = useState<TCountryCode | "">("")
  const [countryNative, setCountryNative] = useState<string>("")
  const [countryDatas, setCountryDatas] = useState<[TCountryCode, ICountry][]>([])
  const [countryDropdownItems, setCountryDropdownItems] = useState<ItemOption[]>([])
  const [jobTitle, setJobTitle] = useState<string>("")
  const [isJobTitleValid, setIsJobTitleValid] = useState<InputFieldBasicStateType>("default")
  const [organizationName, setOrganizationName] = useState<string>("")
  const [isOrganizationNameValid, setIsOrganizationNameValid] = useState<InputFieldBasicStateType>("default")
  const [promotionCode, setPromotionCode] = useState<string>("")
  const [isPromotionCodeValid, setIsPromotionCodeValid] = useState<InputFieldBasicStateType>("default")
  const [checkAgree, setCheckAgree] = useState<boolean>(false)
  const [isCheckAgreeValid, setIsCheckAgreeValid] = useState<boolean>(true)
  const [checkCertify, setCheckCertify] = useState<boolean>(false)
  const [isCheckCertifyValid, setIsCheckCertifyValid] = useState<boolean>(true)
  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const languages = useRef<string[]>([])

  const legalCheckboxRef = useRef<HTMLInputElement>(null)
  const certifyCheckboxRef = useRef<HTMLInputElement>(null)

  const countryDropdownItemsMemo: ItemOption[] = useMemo(() => {
    setCountryDatas(Object.entries(countries) as [TCountryCode, ICountry][])
    return Object.entries(countries).map((_data) => {
      return { text: _data[1].native, value: _data[1].native }
    })
  }, [])

  useEffect(() => {
    setCountryDropdownItems(countryDropdownItemsMemo)
    const countryId = getUserCountry()?.id
    const _data = countryId ? countryDatas.filter((_country) => _country[0] === countryId) : []

    if (_data.length) {
      setCountryCode(_data[0][0])
      setCountryNative(_data[0][1].native)
      languages.current = _data[0][1].languages
    }

    return () => {
      setEmail("")
      setIsEmailValid("default")
      setEmailError("")
      setPassword("")
      setIsPasswordValid("default")
      setConfirmPassword("")
      setIsConfirmPasswordValid("default")
      setFirstName("")
      setIsFirstNameValid("default")
      setLastName("")
      setIsLastNameValid("default")
      setCountryCode("")
      setCountryNative("")
      setJobTitle("")
      setIsJobTitleValid("default")
      setOrganizationName("")
      setIsOrganizationNameValid("default")
      setPromotionCode("")
      setIsPromotionCodeValid("default")
      setCheckAgree(false)
      setIsCheckAgreeValid(true)
      setCheckCertify(false)
      setIsCheckCertifyValid(true)
      setIsRequesting(false)
      languages.current = []
    }
  }, [countryDatas, countryDropdownItemsMemo, t])

  const isSignUpDisabled =
    !email ||
    !password ||
    !confirmPassword ||
    !firstName ||
    !lastName ||
    !countryCode ||
    !jobTitle ||
    !organizationName ||
    !checkAgree ||
    !checkCertify

  const onSignUpButtonClick = () => {
    const emailElement = document.getElementById("email") as HTMLInputElement
    const passwordElement = document.getElementById("password") as HTMLInputElement
    const confirmPasswordElement = document.getElementById("confirmPassword") as HTMLInputElement
    if (!emailElement.checkValidity() || !passwordElement.checkValidity() || !confirmPasswordElement.checkValidity()) return

    setIsRequesting(true)
    let invalid = false

    if ((isEmailValid === "error" && emailError === "REGISTERED") || isPromotionCodeValid === "error") {
      setIsRequesting(false)
      invalid = true
      return
    }
    if (emailReg.test(email)) {
      setEmailError("")
      setIsEmailValid("default")
    } else {
      setEmailError("INCORRECT")
      setIsEmailValid("error")
      invalid = true
    }

    if (passwordReg.test(password) && password.length > 7 && password.length < 33) {
      setIsPasswordValid("default")
    } else {
      setIsPasswordValid("error")
      invalid = true
    }

    if (confirmPassword === password) {
      setIsConfirmPasswordValid("default")
    } else {
      setIsConfirmPasswordValid("error")
      invalid = true
    }

    setIsCheckAgreeValid(checkAgree)
    setIsCheckCertifyValid(checkCertify)

    if (invalid) {
      setIsRequesting(false)
      return
    }

    const postMemberAsync = async () => {
      const data: MemberRequestType = {
        lastName,
        firstName,
        email,
        password,
        countryCode,
        jobTitle,
        organizationName,
        promotionCode: promotionCode || null,
      }
      try {
        const acceptLanguages = languages.current
        if (!acceptLanguages.includes("en")) acceptLanguages.push("en")
        const _member = await postSignUp(data, acceptLanguages.join(","))
        if (_member) {
          navigate(PATH_ONBOARDING, { replace: true, state: { [OE_EMAIL]: email } })
        } else {
          Toast({ message: t("toast.signup.error"), type: "error" })
          captureException("Sign Up error", (scope) => {
            scope.setContext("member", { member: _member })
            return scope
          })
        }
      } catch (e) {
        const res = e as AxiosResponse

        if (res.status === 403) {
          Toast({ message: res.data.errorInfo.message, type: "error" })
          navigate(PATH_SIGN_IN, { replace: true })
        } else if (res.status === 404) {
          setIsPromotionCodeValid("error")
        } else if (res.status === 409) {
          setEmailError("REGISTERED")
          setIsEmailValid("error")
        } else {
          Toast({ message: t("toast.signup.error"), type: "error" })
          captureException(e)
        }
      } finally {
        setIsRequesting(false)
      }
    }
    void postMemberAsync()
  }

  const onChange = (target: FormContent, e: React.ChangeEvent<HTMLInputElement>) => {
    switch (target) {
      case "EMAIL":
        setEmailError("")
        setIsEmailValid("default")
        setEmail(e.target.value)
        return
      case "PASSWORD":
        setIsPasswordValid("default")
        setPassword(e.target.value)
        return
      case "CONFIRM_PASSWORD":
        setIsConfirmPasswordValid("default")
        setConfirmPassword(e.target.value)
        return
      case "FIRST_NAME":
        setIsFirstNameValid("default")
        setFirstName(e.target.value)
        return
      case "LAST_NAME":
        setIsLastNameValid("default")
        setLastName(e.target.value)
        return
      case "JOB_TITLE":
        setIsJobTitleValid("default")
        setJobTitle(e.target.value)
        return
      case "NAME_OF_ORGANIZATION":
        setIsOrganizationNameValid("default")
        setOrganizationName(e.target.value)
        return
      case "PROMOTION_CODE":
        setIsPromotionCodeValid("default")
        setPromotionCode(e.target.value)
        return
      default:
        return
    }
  }

  return (
    <div className={styles.signUp}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <IconButton
            tabIndex={-1}
            size={"large"}
            type={"square"}
            htmlType={"button"}
            icon={<ArrowBackOutlined />}
            onClick={() => navigate(PATH_SIGN_IN, { replace: true })}
            onSubmit={(e) => e.preventDefault()}
          />
          <p>{t("signUp.title")}</p>
        </div>
        <form
          onKeyDown={(e) => {
            if ((e.code === "Enter" || e.code === "Space") && document.activeElement?.id === "legalCheckboxLabel")
              legalCheckboxRef.current?.click()
            else if ((e.code === "Enter" || e.code === "Space") && document.activeElement?.id === "certifyCheckboxLabel")
              certifyCheckboxRef.current?.click()
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          <InputField
            id={"email"}
            tabIndex={1}
            size={"large"}
            type={"text"}
            autoComplete={"email"}
            autoFocus={true}
            helperText={(isEmailValid === "error" ? t("textInput.email.helperText.label") : "") ?? ""}
            label={t("textInput.email.label") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.email.label").toLowerCase() }) ?? ""}
            state={isEmailValid}
            onChange={(e) => onChange("EMAIL", e)}
            onClearButtonClick={() => setEmail("")}
          />
          <InputField
            helperTextClassName={styles.passwordHelperText}
            id={"password"}
            tabIndex={2}
            size={"large"}
            type={"password"}
            helperText={
              (isPasswordValid === "error"
                ? t("textInput.password.helperText.incorrect")
                : t("textInput.password.helperText.default")) ?? ""
            }
            autoComplete={"new-password"}
            label={t("textInput.password.title") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.password.title").toLowerCase() }) ?? ""}
            state={isPasswordValid}
            onChange={(e) => onChange("PASSWORD", e)}
            onClearButtonClick={() => setPassword("")}
          />
          <InputField
            id={"confirmPassword"}
            tabIndex={3}
            size={"large"}
            type={"password"}
            helperText={
              (isConfirmPasswordValid === "error" ? t("textInput.confirmPassword.helperText.incorrect") : "") ?? ""
            }
            autoComplete={"new-password"}
            label={t("textInput.confirmPassword.title") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.confirmPassword.title").toLowerCase() }) ?? ""}
            state={isConfirmPasswordValid}
            onChange={(e) => onChange("CONFIRM_PASSWORD", e)}
            onClearButtonClick={() => setConfirmPassword("")}
          />
          <div className={styles.horizontal}>
            <InputField
              id={"firstName"}
              tabIndex={4}
              size={"large"}
              type={"text"}
              autoComplete={"given-name"}
              label={t("textInput.firstName") ?? ""}
              placeholder={t("textInput.placeholder", { target: t("textInput.firstName").toLowerCase() }) ?? ""}
              state={isFirstNameValid}
              onChange={(e) => onChange("FIRST_NAME", e)}
              onClearButtonClick={() => setFirstName("")}
            />
            <InputField
              id={"lastName"}
              tabIndex={5}
              size={"large"}
              type={"text"}
              autoComplete={"family-name"}
              label={t("textInput.lastName") ?? ""}
              placeholder={t("textInput.placeholder", { target: t("textInput.lastName").toLowerCase() }) ?? ""}
              state={isLastNameValid}
              onChange={(e) => onChange("LAST_NAME", e)}
              onClearButtonClick={() => setLastName("")}
            />
          </div>
          <Dropdown
            size={"large"}
            type={"single"}
            itemList={countryDropdownItems}
            labelText={t("textInput.country.title") ?? ""}
            placeholder={t("dropdown.placeholder.title", { target: t("textInput.country.title").toLowerCase() }) ?? ""}
            search={true}
            searchPlaceholder={t("dropdown.searchPlaceholder.title") ?? ""}
            value={countryNative}
            onChange={(e) => {
              const _native = e as string
              const _code = getCountryCode(_native)
              if (_code) {
                setCountryCode(_code)
                setCountryNative(_native)
                languages.current = getCountryData(_code).languages
              }
            }}
          />
          <InputField
            id={"jobTitle"}
            tabIndex={6}
            size={"large"}
            type={"text"}
            autoComplete={"organization-title"}
            label={t("textInput.jobTitle") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.jobTitle").toLowerCase() }) ?? ""}
            state={isJobTitleValid}
            onChange={(e) => onChange("JOB_TITLE", e)}
            onClearButtonClick={() => setJobTitle("")}
          />
          <InputField
            id={"organization"}
            tabIndex={7}
            size={"large"}
            type={"text"}
            autoComplete={"organization"}
            label={t("textInput.nameOfOrganization") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.nameOfOrganization").toLowerCase() }) ?? ""}
            state={isOrganizationNameValid}
            onChange={(e) => onChange("NAME_OF_ORGANIZATION", e)}
            onClearButtonClick={() => setOrganizationName("")}
          />
          <InputField
            id={"promotionCode"}
            labelClassName={"ods-input-optional"}
            tabIndex={8}
            size={"large"}
            type={"text"}
            autoComplete={"off"}
            helperText={(isPromotionCodeValid === "error" ? t("textInput.promotionCode.helperText.incorrect") : "") ?? ""}
            label={t("textInput.promotionCode.title") + " "}
            placeholder={t("textInput.placeholder", { target: t("textInput.promotionCode.title").toLowerCase() }) ?? ""}
            state={isPromotionCodeValid}
            onChange={(e) => onChange("PROMOTION_CODE", e)}
            onClearButtonClick={() => setPromotionCode("")}
          />
          <div className={styles.checkboxGroup}>
            <Checkbox
              className={cn(styles.checkbox, !isCheckAgreeValid && styles.required)}
              id={"legalCheckbox"}
              ref={legalCheckboxRef}
              tabIndex={9}
              active={checkAgree}
              onClick={() => setCheckAgree((prev) => !prev)}
            >
              <p>
                <Trans i18nKey={"checkbox.agreeToLegal.label"}>
                  I agree to the
                  <a tabIndex={-1} href={PATH_TERMS_AND_CONDITIONS} rel={"noopener noreferrer"} target={"_blank"}>
                    Terms
                  </a>
                  ,
                  <a tabIndex={-1} href={PATH_PRIVACY_POLICY} rel={"noopener noreferrer"} target={"_blank"}>
                    Privacy
                  </a>
                  and
                  <a tabIndex={-1} href={PATH_END_USER_LICENSE_AGREEMENT} rel={"noopener noreferrer"} target={"_blank"}>
                    EULA
                  </a>
                  .{{ terms: t("termsAndConditions.label") }}
                  {{ privacy: t("privacyPolicy.title") }}
                  {{ eula: t("eula.abbreviation.title") }}
                </Trans>
              </p>
            </Checkbox>
            <Checkbox
              className={cn(styles.checkbox, !isCheckCertifyValid && styles.required)}
              id={"certifyCheckbox"}
              ref={certifyCheckboxRef}
              tabIndex={10}
              active={checkCertify}
              onClick={() => setCheckCertify((prev) => !prev)}
            >
              <p>{t("checkbox.certifyOfAge.title")}</p>
            </Checkbox>
          </div>
          <Button
            className={styles.signUpBtn}
            size={"xl"}
            type={"cta"}
            disabled={isSignUpDisabled}
            htmlType={"submit"}
            loading={isRequesting}
            onClick={onSignUpButtonClick}
          >
            {t("button.signUp")}
          </Button>
        </form>
        <p className={styles.navigateToSignIn}>
          <Trans i18nKey={"signUp.navigateToSignIn"}>
            Already have an account?
            <Link replace={true} to={PATH_SIGN_IN} onKeyDown={(e) => e.preventDefault()}>
              Sign in
            </Link>
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default SignUp
