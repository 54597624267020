import { Nullable } from "../../common/common"

export type CurrencyType = "KRW" | "USD" | ""

export const isCurrencyValid = (currency?: string | null): currency is CurrencyType => {
  return currency === "KRW" || currency === "USD"
}

export type Product = {
  id: number
  productName: {
    planId: number
  }
  displayName: string
  quantity: number
  unitAmount: number
  currency: CurrencyType
  name: string // @NOTE Front handling field for TOSS PayPal
  description: string // @NOTE Front handling field for TOSS PayPal
}
export type Customer = {
  accountId: string
  key: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  createdTime: Date | ""
}
// @NOTE Front handling type for TOSS PayPal
export type PayPalStc = {
  senderAccountId: string
  senderFirstName: string
  senderLastName: string
  senderEmail: string
  senderPhone: string
  senderCountryCode: string
  senderCreateDate: Date | ""
}
// @NOTE Front handling type for TOSS PayPal
export type PaymentMethodOptions = {
  paypal: {
    setTransactionContext: PayPalStc
  }
}
export type Order = {
  id: number
  orderKey: string
  customerKey: string
  customerEmail: string
  customerName: string
  customerCountryCode: string
  currency: CurrencyType
  displayName: string
  creditAmount: number
  amount: number
  products: Product[]
  customer: Customer
  country: string // @NOTE Front handling field for TOSS PayPal
  paymentMethodOptions: PaymentMethodOptions // @NOTE Front handling field for TOSS PayPal
}

type ProductToNullable = Omit<Product, "productName" | "currency" | "name" | "description">
type ProductDto = {
  productName: string | null
  currency: string | null
} & {
  [key in keyof ProductToNullable]: Nullable<ProductToNullable[key]>
}
type CustomerToNullable = Omit<Customer, "createdTime">
type CustomerDto = {
  createdTime: string | null
} & {
  [key in keyof CustomerToNullable]: Nullable<CustomerToNullable[key]>
}
type OrderToNullable = Omit<Order, "currency" | "products" | "customer" | "country" | "paymentMethodOptions">
export type OrderDto = {
  currency: string | null
  products: ProductDto[] | null
  customer: CustomerDto | null
} & {
  [key in keyof OrderToNullable]: Nullable<OrderToNullable[key]>
}

const productFromDto = (dto: ProductDto): Product => {
  const _currency = isCurrencyValid(dto.currency) ? dto.currency : ""
  const _planId = parseInt(dto.productName?.split(":")[1] || "-1")
  return {
    id: dto.id ?? -1,
    productName: { planId: _planId },
    displayName: dto.displayName ?? "",
    quantity: dto.quantity ?? 0,
    unitAmount: dto.unitAmount ?? 0,
    currency: _currency,
    name: dto.displayName ?? "",
    description: dto.displayName ?? "",
  }
}
const customerFromDto = (dto: CustomerDto | null): Customer => {
  if (!dto) return initCustomer()
  return {
    accountId: dto.accountId ?? "",
    key: dto.key ?? "",
    firstName: dto.firstName ?? "",
    lastName: dto.lastName ?? "",
    email: dto.email ?? "",
    countryCode: dto.countryCode ?? "",
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
  }
}
const customerToPaymentMethodOptions = (customer: Customer): PaymentMethodOptions => {
  return {
    paypal: {
      setTransactionContext: {
        senderAccountId: customer.accountId,
        senderFirstName: customer.firstName,
        senderLastName: customer.lastName,
        senderEmail: customer.email,
        senderPhone: "", // @NOTE BE에서 저장하지 않아 제공하지 않음
        senderCountryCode: customer.countryCode,
        senderCreateDate: customer.createdTime,
      },
    },
  }
}

export const orderFromDto = (dto: OrderDto): Order => {
  const _currency = isCurrencyValid(dto.currency) ? dto.currency : ""
  const _customer = customerFromDto(dto.customer)
  return {
    id: dto.id ?? -1,
    orderKey: dto.orderKey ?? "",
    customerKey: dto.customerKey ?? "",
    customerEmail: dto.customerEmail ?? "",
    customerName: dto.customerName ?? "",
    customerCountryCode: dto.customerCountryCode ?? "",
    currency: _currency,
    displayName: dto.displayName ?? "",
    creditAmount: dto.creditAmount ?? 0,
    amount: dto.amount ?? 0,
    products: dto.products?.map((product) => productFromDto(product)) ?? [],
    customer: _customer,
    country: dto.customerCountryCode ?? "",
    paymentMethodOptions: customerToPaymentMethodOptions(_customer),
  }
}

export const isInitialOrder = (order: Order) => {
  return order.id === -1
}

const initProduct = (): Product => {
  return {
    id: -1,
    productName: {
      planId: -1,
    },
    displayName: "",
    quantity: 0,
    unitAmount: 0,
    currency: "",
    name: "",
    description: "",
  }
}
const initCustomer = (): Customer => {
  return {
    accountId: "",
    key: "",
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    createdTime: "",
  }
}
const initPaymentMethodOptions = (): PaymentMethodOptions => {
  return {
    paypal: {
      setTransactionContext: {
        senderAccountId: "",
        senderFirstName: "",
        senderLastName: "",
        senderEmail: "",
        senderPhone: "",
        senderCountryCode: "",
        senderCreateDate: "",
      },
    },
  }
}
export const initOrder = (): Order => {
  return {
    id: -1,
    orderKey: "",
    customerKey: "",
    customerEmail: "",
    customerName: "",
    customerCountryCode: "",
    currency: "",
    displayName: "",
    creditAmount: 0,
    amount: 0,
    products: [initProduct()],
    customer: initCustomer(),
    country: "",
    paymentMethodOptions: initPaymentMethodOptions(),
  }
}
