import {
  ErrorPage,
  Fallback,
  ForgotPassword,
  Layout,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_ONBOARDING,
  PATH_ONBOARDING_EXPIRED,
  PATH_RESET_PASSWORD,
  PATH_SIGN_IN,
  PATH_SIGN_UP,
  PATH_VERIFY,
  ResetPassword,
  SignIn,
  SignInLayout,
  SignUp,
  VerifySignUpCode,
  SignUpOnboarding,
  SignUpEmailExpired,
  VerifyResetPasswordCode,
  PATH_VERIFY_RESET_PASSWORD,
  ResetPasswordOnboarding,
  PATH_ONBOARDING_RESET_PASSWORD,
  ResetPasswordEmailExpired,
  PATH_RESET_PASSWORD_EXPIRED,
  OauthGoogleCode,
  PATH_OAUTH_GOOGLE,
  loadCookie,
  deleteCookie,
  PATH_USER_MANAGEMENT,
  getToken,
  SignInAddInfo,
  PATH_SIGN_IN_ADD_INFO,
  PATH_CREDIT_MANAGEMENT,
} from "@ovision-gis-frontend/shared"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom"

import CreditManagement from "./routes/credit-management/CreditManagement"
import Home, { HomeLoader } from "./routes/home/Home"
import UserManagement from "./routes/user-management/UserManagement"

import "./styledAntd.scss"
import "./App.scss"

function Redirect() {
  if (getToken()) return <Navigate replace={true} to={PATH_USER_MANAGEMENT} />
  else return <Navigate replace={true} to={PATH_SIGN_IN} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />} errorElement={<ErrorPage />}>
      <Route element={<Redirect />} path={PATH_HOME} />
      <Route id={"home"} element={<Home />} loader={HomeLoader}>
        <Route element={<UserManagement />} path={PATH_USER_MANAGEMENT} />
        <Route element={<CreditManagement />} path={PATH_CREDIT_MANAGEMENT} />
      </Route>
      <Route element={<SignInLayout isAdminWeb={true} />}>
        <Route element={<SignIn isAdmin={true} />} path={PATH_SIGN_IN} />
        <Route element={<OauthGoogleCode />} path={PATH_OAUTH_GOOGLE} />
        <Route element={<SignUp />} path={PATH_SIGN_UP} />
        <Route element={<VerifySignUpCode />} path={PATH_VERIFY} />
        <Route element={<SignUpOnboarding />} path={PATH_ONBOARDING} />
        <Route element={<SignUpEmailExpired />} path={PATH_ONBOARDING_EXPIRED} />
        <Route element={<ForgotPassword />} path={PATH_FORGOT_PASSWORD} />
        <Route element={<ResetPassword />} path={PATH_RESET_PASSWORD} />
        <Route element={<VerifyResetPasswordCode />} path={PATH_VERIFY_RESET_PASSWORD} />
        <Route element={<ResetPasswordOnboarding />} path={PATH_ONBOARDING_RESET_PASSWORD} />
        <Route element={<ResetPasswordEmailExpired />} path={PATH_RESET_PASSWORD_EXPIRED} />
        <Route element={<SignInAddInfo />} path={PATH_SIGN_IN_ADD_INFO} />
      </Route>
      <Route element={<ErrorPage />} path={"/*"} />
    </Route>,
  ),
)

function App() {
  const { ready } = useTranslation()

  useEffect(() => {
    //인증 방식 변경 후 쿠키에 access_token이 남아있어 로그인 시 문제가 생기는 것을 방지함.
    if (loadCookie("access_token")) deleteCookie("access_token", `.${window.location.hostname}`)
  }, [])

  return (
    <div className={"app"}>{ready ? <RouterProvider fallbackElement={<Fallback />} router={router} /> : <Fallback />}</div>
  )
}

export default App
