import { Nullable } from "../../common/common"
import { AccountId } from "../../common/utils/accountUtil"
import { AccountInfo, AccountInfoDto, accountInfoFromDto, initAccountInfo } from "./accountInfoDto"
import { AdminScene, AdminSceneDto, adminSceneFromDto } from "./adminScenesDto"
import { AnalysisStatusType, isAnalysisStatusValid } from "./inferenceJobInfosDto"
import { isProjectValid, ProjectType } from "./projectDto"

export type AdminInferenceStatusType = AnalysisStatusType | "SKIP" | "CANCELED"
export const isAdminInferenceStatusValid = (status: string | null): status is AdminInferenceStatusType => {
  return isAnalysisStatusValid(status) || status === "SKIP" || status === "CANCELED"
}

type AdminProject = {
  id: string
  name: string
  type: ProjectType
}
type AdminAiPack = {
  id: string
  name: string
  displayName: string
}
type InferenceHistory = {
  id: string
  jobName: string
  scenes: AdminScene[]
  project: AdminProject
  aiPack: AdminAiPack
  aoi: string
  aoiArea: number
  creditAmount: number
  inferenceDuration: number
  inferenceTime: Date | ""
  inferenceStatus: AdminInferenceStatusType
  accountId: AccountId
}
export type AdminInferenceHistory = {
  inferenceHistory: InferenceHistory
  accountInfo: AccountInfo
}
export type AdminInferenceHistories = {
  results: AdminInferenceHistory[]
  nextPages: string[]
}

type AdminProjectDto = {
  [key in keyof AdminProject]: Nullable<AdminProject[key]>
}
type AdminAiPackDto = {
  [key in keyof AdminAiPack]: Nullable<AdminAiPack[key]>
}
type InferenceHistoryToNullable = Omit<InferenceHistory, "scenes" | "project" | "aiPack" | "inferenceTime">
type InferenceHistoryDto = {
  scenes: AdminSceneDto[] | null
  project: AdminProjectDto | null
  aiPack: AdminAiPack | null
  inferenceTime: string | null
} & {
  [key in keyof InferenceHistoryToNullable]: Nullable<InferenceHistoryToNullable[key]>
}
type AdminInferenceHistoryToNullable = Omit<AdminInferenceHistory, "inferenceHistory" | "accountInfo">
export type AdminInferenceHistoryDto = {
  inferenceHistory: InferenceHistoryDto | null
  accountInfo: AccountInfoDto | null
} & {
  [key in keyof AdminInferenceHistoryToNullable]: Nullable<AdminInferenceHistoryToNullable[key]>
}
type AdminInferenceHistoriesToNullable = Omit<AdminInferenceHistories, "results">
export type AdminInferenceHistoriesDto = {
  results: AdminInferenceHistoryDto[] | null
} & {
  [key in keyof AdminInferenceHistoriesToNullable]: Nullable<AdminInferenceHistoriesToNullable[key]>
}

const adminProjectFromDto = (dto: AdminProjectDto | null): AdminProject => {
  if (!dto) return initAdminProject()
  const _type = isProjectValid(dto.type) ? dto.type : ""
  return {
    id: dto.id ?? "",
    name: dto.name ?? "",
    type: _type,
  }
}
const adminAiPackFromDto = (dto: AdminAiPackDto | null): AdminAiPack => {
  if (!dto) return initAdminAiPack()
  return {
    id: dto.id ?? "",
    name: dto.name ?? "",
    displayName: dto.displayName ?? "",
  }
}
const inferenceHistoryFromDto = (dto: InferenceHistoryDto | null): InferenceHistory => {
  if (!dto) return initInferenceHistory()
  const _status = isAdminInferenceStatusValid(dto.inferenceStatus) ? dto.inferenceStatus : ""
  return {
    id: dto.id ?? "",
    jobName: dto.jobName ?? "",
    scenes: dto.scenes?.map((scene) => adminSceneFromDto(scene)) ?? [],
    project: adminProjectFromDto(dto.project),
    aiPack: adminAiPackFromDto(dto.aiPack),
    aoi: dto.aoi ?? "",
    aoiArea: dto.aoiArea ?? 0,
    creditAmount: dto.creditAmount ?? 0,
    inferenceDuration: dto.inferenceDuration ?? 0,
    inferenceTime: dto.inferenceTime ? new Date(dto.inferenceTime) : "",
    inferenceStatus: _status,
    accountId: dto.accountId ?? "user:-1",
  }
}
export const adminInferenceHistoryFromDto = (dto: AdminInferenceHistoryDto): AdminInferenceHistory => {
  return {
    inferenceHistory: inferenceHistoryFromDto(dto.inferenceHistory),
    accountInfo: accountInfoFromDto(dto.accountInfo),
  }
}
export const adminInferenceHistoriesFromDto = (dto: AdminInferenceHistoriesDto): AdminInferenceHistories => {
  return {
    results: dto.results?.map((result) => adminInferenceHistoryFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}

const initAdminProject = (): AdminProject => {
  return {
    id: "",
    name: "",
    type: "",
  }
}
const initAdminAiPack = (): AdminAiPack => {
  return {
    id: "",
    name: "",
    displayName: "",
  }
}
const initInferenceHistory = (): InferenceHistory => {
  return {
    id: "",
    jobName: "",
    scenes: [],
    project: initAdminProject(),
    aiPack: initAdminAiPack(),
    aoi: "",
    aoiArea: 0,
    creditAmount: 0,
    inferenceDuration: 0,
    inferenceTime: "",
    inferenceStatus: "",
    accountId: "user:-1",
  }
}
export const initAdminInferenceHistory = (): AdminInferenceHistory => {
  return {
    inferenceHistory: initInferenceHistory(),
    accountInfo: initAccountInfo(),
  }
}
export const initAdminInferenceHistories = (): AdminInferenceHistories => {
  return {
    results: [],
    nextPages: [],
  }
}

export const isInitialAdminInferenceHistory = (dto: AdminInferenceHistory) => {
  return dto.inferenceHistory.id === ""
}
