import {
  AdminInferenceHistory,
  AdminInferenceStatusType,
  convertSecToTime,
  getAdminInferenceStatusLabel,
  getDateLabel,
  getFormattedArea,
  getFormattedGsd,
  getProjectTypeLabel,
  isInitialAdminInferenceHistory,
} from "@ovision-gis-frontend/shared"
import { Chip } from "@SIAnalytics/ovision-design-system"
import React from "react"

import styles from "./ViewerDetail.module.scss"

type Props = {
  adminInferenceHistory: AdminInferenceHistory
}

const renderStatus = (status: AdminInferenceStatusType) => {
  switch (getAdminInferenceStatusLabel(status)) {
    case "WAITING":
      return <Chip size={"small"} color={"grey"} label={"대기 중"} />
    case "PROCESSING":
      return <Chip size={"small"} color={"green"} label={"처리 중"} />
    case "POSTPROCESSING":
      return <Chip size={"small"} color={"green"} label={"후처리 중"} />
    case "ERROR":
      return <Chip className={"red"} size={"small"} label={"분석 오류"} />
    case "COMPLETED":
      return <Chip size={"small"} color={"blue"} label={"완료됨"} />
    case "CANCELED":
      return <Chip size={"small"} color={"yellow"} label={"취소됨"} />
    default:
      return <></>
  }
}

function InferenceViewerDetail(props: { adminInferenceHistory: AdminInferenceHistory }) {
  const _inferenceHistory = props.adminInferenceHistory.inferenceHistory
  const _scene = _inferenceHistory.scenes.at(-1)
  const _accountInfo = props.adminInferenceHistory.accountInfo

  return (
    <section className={styles.viewerDetail}>
      <div className={styles.detail}>
        <span className={styles.label}>{"프로젝트명"}</span>
        <span className={styles.data}>{_inferenceHistory.project.name || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"프로젝트 유형"}</span>
        <span className={styles.data}>{getProjectTypeLabel(_inferenceHistory.project.type, true) || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"AI Pack"}</span>
        <span className={styles.data}>{_inferenceHistory.aiPack.displayName || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"지역"}</span>
        <span className={styles.data}>{`${_scene?.region.city || "-"} / ${_scene?.region.state || "-"}`}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"면적"}</span>
        <span className={styles.data}>{getFormattedArea(_inferenceHistory.aoiArea) || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"사용자 계정"}</span>
        <span className={styles.data}>{_accountInfo.profile.email || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"크레딧"}</span>
        <span className={styles.data}>{_inferenceHistory.creditAmount || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"소요 시간"}</span>
        <span className={styles.data}>{`${convertSecToTime(_inferenceHistory.inferenceDuration)}` || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"분석 일시"}</span>
        <span className={styles.data}>{getDateLabel(_inferenceHistory.inferenceTime, "TIL_SEC") || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"촬영 일시"}</span>
        <span className={styles.data}>{getDateLabel(_scene?.imagingTime, "TIL_SEC") || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"상태"}</span>
        <span className={styles.data}>{renderStatus(_inferenceHistory.inferenceStatus)}</span>
      </div>
    </section>
  )
}
function ImageViewerDetail(props: { adminInferenceHistory: AdminInferenceHistory }) {
  const _inferenceHistory = props.adminInferenceHistory.inferenceHistory
  const _scene = _inferenceHistory.scenes.at(-1)
  const _accountInfo = props.adminInferenceHistory.accountInfo

  return (
    <section className={styles.viewerDetail}>
      <div className={styles.detail}>
        <span className={styles.label}>{"지역"}</span>
        <span className={styles.data}>{`${_scene?.region.city || "-"} / ${_scene?.region.state || "-"}`}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"위성"}</span>
        <span className={styles.data}>{_scene?.satellite || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"GSD"}</span>
        <span className={styles.data}>{getFormattedGsd(_scene?.gsdX, _scene?.gsdY) || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"사용자 계정"}</span>
        <span className={styles.data}>{_accountInfo.profile.email || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"촬영 일시"}</span>
        <span className={styles.data}>{getDateLabel(_scene?.imagingTime, "TIL_SEC") || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"업로드 일시"}</span>
        <span className={styles.data}>{getDateLabel(_scene?.createdTime, "TIL_SEC") || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{"상태"}</span>
        <span className={styles.data}>{renderStatus(_inferenceHistory.inferenceStatus)}</span>
      </div>
    </section>
  )
}

function ViewerDetail(props: Props) {
  const _isNotError = props.adminInferenceHistory.inferenceHistory.inferenceStatus !== "ERROR"

  return isInitialAdminInferenceHistory(props.adminInferenceHistory) ? (
    <></>
  ) : _isNotError ? (
    <InferenceViewerDetail adminInferenceHistory={props.adminInferenceHistory} />
  ) : (
    <ImageViewerDetail adminInferenceHistory={props.adminInferenceHistory} />
  )
}

export default ViewerDetail
