import { captureException } from "@sentry/react"
import { Button, InputField, Toast } from "@SIAnalytics/ovision-design-system"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import { OE_RESET_PASSWORD_CODE, PATH_SIGN_IN, putResetPassword } from "../../.."
import { passwordReg } from "../../common/regularExpressions"
import styles from "./ResetPassword.module.scss"

function ResetPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true)
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(true)
  const code = OE_RESET_PASSWORD_CODE in location.state ? location.state[OE_RESET_PASSWORD_CODE] : ""

  const checkValidInput = () => {
    const isPasswordValid = passwordReg.test(password)
    setIsPasswordValid(isPasswordValid)
    const isConfirmPasswordValid = password === confirmPassword
    setIsConfirmPasswordValid(isConfirmPasswordValid)
    return isConfirmPasswordValid && isPasswordValid
  }

  const handleSubmit = async () => {
    const isValid = checkValidInput()
    if (!isValid) return
    try {
      await putResetPassword(code, password)
      Toast({ type: "success", message: t("toast.resetPassword.success") })
    } catch (e) {
      Toast({ type: "error", message: t("toast.resetPassword.error") })
      captureException(e)
    } finally {
      navigate(PATH_SIGN_IN, { replace: true })
    }
  }

  return (
    <div className={styles.resetPassword}>
      <div className={styles.content}>
        <p className={styles.title}>{t("resetPassword.title")}</p>
        <p className={styles.desc}>{t("resetPassword.desc")}</p>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            id={"password"}
            tabIndex={1}
            size={"large"}
            type={"password"}
            helperText={
              (!isPasswordValid
                ? t("textInput.password.helperText.incorrect")
                : t("textInput.password.helperText.default")) ?? ""
            }
            autoComplete={"new-password"}
            label={t("textInput.password.title") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.password.title").toLowerCase() }) ?? ""}
            state={isPasswordValid ? "default" : "error"}
            onChange={(e) => {
              setPassword(e.target.value)
              setIsPasswordValid(true)
            }}
            onClearButtonClick={() => setPassword("")}
          />
          <InputField
            id={"confirmPassword"}
            tabIndex={2}
            size={"large"}
            type={"password"}
            autoComplete={"new-password"}
            helperText={(!isConfirmPasswordValid ? t("textInput.confirmPassword.helperText.incorrect") : "") ?? ""}
            label={t("textInput.confirmPassword.title") ?? ""}
            placeholder={t("textInput.placeholder", { target: t("textInput.confirmPassword.title").toLowerCase() }) ?? ""}
            state={isConfirmPasswordValid ? "default" : "error"}
            onChange={(e) => {
              setConfirmPassword(e.target.value)
              setIsConfirmPasswordValid(true)
            }}
            onClearButtonClick={() => setConfirmPassword("")}
          />
          <Button
            size={"xl"}
            type={"cta"}
            disabled={!password || !confirmPassword}
            htmlType={"submit"}
            onClick={handleSubmit}
          >
            {t("resetPassword.title")}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
