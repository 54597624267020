import { Nullable } from "../../common/common"
import {
  AccountId,
  permissionGroupRoleAdmin,
  permissionGroupRoleSuperAdmin,
  PermissionGroupType,
  PermissionType,
} from "../../common/utils/accountUtil"

export type AdminAccountPermissionQueryType = {
  category: "admin" | "service-management" | "access" | "inference" | "inference.ai-pack" | "role"
}
type AdminAccountPermissionRequestType = Omit<AdminAccountPermission, "isSuperAdmin" | "isAdmin">[]
export type AdminAccountPermissionsRequestType = Record<AdminAccountPermissionKey, AdminAccountPermissionRequestType>

type AdminAccountPermissionKey = "accountPermissions"
export type AdminAccountPermission = {
  accountId: AccountId
  permissionGroups: PermissionGroupType[]
  permissions: PermissionType[]
  isSuperAdmin: boolean // @NOTE: front handling field
  isAdmin: boolean // @NOTE: front handling field
}
type AdminAccountPermissionToNullable = Omit<AdminAccountPermission, "accountId" | "permissionGroups" | "permissions">
type AdminAccountPermissionDto = {
  accountId: AccountId | null
  permissionGroups: PermissionGroupType[] | null
  permissions: PermissionType[] | null
} & {
  [key in keyof AdminAccountPermissionToNullable]: Nullable<AdminAccountPermissionToNullable[key]>
}
export type AdminAccountPermissions = Record<AdminAccountPermissionKey, AdminAccountPermission[]>
export type AdminAccountPermissionsDto = Record<AdminAccountPermissionKey, AdminAccountPermissionDto[] | null>

const adminAccountPermissionFromDto = (dto: AdminAccountPermissionDto): AdminAccountPermission => {
  const _isSuperAdmin = dto.permissionGroups?.includes(permissionGroupRoleSuperAdmin) ?? false
  const _isAdmin = dto.permissionGroups?.includes(permissionGroupRoleAdmin) ?? false
  return {
    accountId: dto.accountId ?? "user:-1",
    permissionGroups: dto.permissionGroups ?? ["permission-group.role."],
    permissions: dto.permissions ?? ["permission."],
    isSuperAdmin: _isSuperAdmin,
    isAdmin: _isAdmin,
  }
}
export const adminAccountPermissionsFromDto = (dto: AdminAccountPermissionsDto): AdminAccountPermissions => {
  return { accountPermissions: dto.accountPermissions?.map((permission) => adminAccountPermissionFromDto(permission)) ?? [] }
}

export const initAdminAccountPermission = (): AdminAccountPermission => {
  return {
    accountId: "user:-1",
    permissionGroups: ["permission-group.role."],
    permissions: ["permission."],
    isSuperAdmin: false,
    isAdmin: false,
  }
}
export const initAdminAccountPermissions = (): AdminAccountPermissions => {
  return { accountPermissions: [] }
}
