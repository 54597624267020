import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { OE_EMAIL, PATH_ONBOARDING_RESET_PASSWORD, postResetPasswordEmail } from "../../.."
import { useEmail } from "./EmailOnboardingLayout"
import VerifyEmailExpired from "./VerifyEmailExpired"

function ResetPasswordEmailExpired() {
  const [isResendRequesting, setIsResendRequesting] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const email = useEmail()

  const sendEmail = () => {
    setIsResendRequesting(true)
    const postValidateAsync = async () => {
      try {
        await postResetPasswordEmail(email)
        Toast({ message: t("toast.verifyEmail.resend.success"), type: "success" })
        navigate(PATH_ONBOARDING_RESET_PASSWORD, { replace: true, state: { [OE_EMAIL]: email } })
      } catch (e) {
        Toast({ message: t("toast.verifyEmail.resend.error"), type: "error" })
        captureException(e)
      } finally {
        setIsResendRequesting(false)
      }
    }
    void postValidateAsync()
  }

  return <VerifyEmailExpired email={email} isResendRequesting={isResendRequesting} onResendClick={sendEmail} />
}

export default ResetPasswordEmailExpired
