import { Nullable } from "../../common/common"
import { CurrencyType, isCurrencyValid } from "./orderDto"

export const isCreditAllResultTypeValid = (result: string | null): result is CreditAllResultType => {
  return (
    result === "RECHARGE" ||
    result === "USAGE" ||
    result === "EXPIRATION" ||
    result === "REFUND" ||
    result === "COMPENSATION"
  )
}

export type CreditAllResultType = "RECHARGE" | "USAGE" | "EXPIRATION" | "REFUND" | "COMPENSATION" | ""
export type CreditAllResult = {
  id: string
  time: Date | ""
  type: CreditAllResultType
  creditAmount: number
  remainCredit: number
  price: number
  currency: CurrencyType
  refundable: boolean
  refundablePrice: number
  refundedPrice: number
}
export type CreditAllResults = {
  results: CreditAllResult[]
  nextPages: string[]
}

type CreditAllResultToNullable = Omit<CreditAllResult, "time" | "type" | "currency">
type CreditAllResultDto = {
  time: string | null
  type: string | null
  currency: string | null
} & {
  [key in keyof CreditAllResultToNullable]: Nullable<CreditAllResultToNullable[key]>
}
type CreditAllResultsToNullable = Omit<CreditAllResults, "results">
export type CreditAllResultsDto = {
  results: CreditAllResultDto[] | null
} & {
  [key in keyof CreditAllResultsToNullable]: Nullable<CreditAllResultsToNullable[key]>
}

export const creditAllResultFromDto = (dto: CreditAllResultDto): CreditAllResult => {
  const _type = isCreditAllResultTypeValid(dto.type) ? dto.type : ""
  const _currency = isCurrencyValid(dto.currency) ? dto.currency : ""
  return {
    id: dto.id ?? "",
    time: dto.time ? new Date(dto.time) : "",
    type: _type,
    creditAmount: dto.creditAmount ?? 0,
    remainCredit: dto.remainCredit ?? 0,
    price: dto.price ?? 0,
    currency: _currency,
    refundable: dto.refundable ?? false,
    refundablePrice: dto.refundablePrice ?? 0,
    refundedPrice: dto.refundedPrice ?? 0,
  }
}
export const creditAllResultsFromDto = (dto: CreditAllResultsDto): CreditAllResults => {
  return {
    results: dto.results?.map((result) => creditAllResultFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}

export const initCreditAllResult = (): CreditAllResult => {
  return {
    id: "",
    time: "",
    type: "",
    creditAmount: 0,
    remainCredit: 0,
    price: 0,
    currency: "",
    refundable: false,
    refundablePrice: 0,
    refundedPrice: 0,
  }
}
export const initCreditAllResults = (): CreditAllResults => {
  return {
    results: [initCreditAllResult()],
    nextPages: [],
  }
}
