import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import { AxiosError } from "axios"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { OE_EMAIL, PATH_ONBOARDING, postVerifyEmail } from "../../.."
import { useEmail } from "./EmailOnboardingLayout"
import VerifyEmailExpired from "./VerifyEmailExpired"

function SignUpEmailExpired() {
  const [isResendRequesting, setIsResendRequesting] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const email = useEmail()

  const sendEmail = () => {
    setIsResendRequesting(true)
    const postValidateAsync = async () => {
      try {
        const _validate = await postVerifyEmail(email)
        if (_validate) {
          Toast({ message: t("toast.verifyEmail.resend.success"), type: "success" })
          navigate(PATH_ONBOARDING, { replace: true, state: { [OE_EMAIL]: email } })
        } else {
          Toast({ message: t("toast.verifyEmail.resend.error"), type: "error" })
          captureException("Resend Error", (scope) => {
            scope.setContext("validate", { validate: _validate })
            return scope
          })
        }
      } catch (e) {
        if ((e as AxiosError)?.status === 409)
          Toast({ message: t("toast.verifyEmail.alreadyVerified.error"), type: "error" })
        else Toast({ message: t("toast.verifyEmail.resend.error"), type: "error" })
        captureException(e)
      } finally {
        setIsResendRequesting(false)
      }
    }
    void postValidateAsync()
  }

  return <VerifyEmailExpired email={email} isResendRequesting={isResendRequesting} onResendClick={sendEmail} />
}

export default SignUpEmailExpired
